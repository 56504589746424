.page-home {
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important; 
}
.page-home .main-wrapper {
  height: 100%;
  margin-top: 0; 
}
@media screen and (min-width: 992px) {
  .page-home .main-wrapper {
    margin-top: 0; 
  } 
}
.page-home #news-notification {
  position: absolute;
  bottom: 60px;
  right: 0;
  background: url(../images/home.notification.bg.png);
  width: 100%;
  padding: 15px 15px 25px;
  color: #fff;
  line-height: 1.4;
  display: none;
  font-size: 8px; 
}
@media screen and (min-width: 992px) {
  .page-home #news-notification {
    font-size: 12px; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home #news-notification {
    width: 50%; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home #news-notification {
    padding: 25px 25px 25px; 
  } 
}
.page-home #news-notification a {
  color: #fff; 
}
.page-home #news-notification a:hover {
  text-decoration: none; 
}
.page-home #news-notification h5 {
  margin: 0 0 5px;
  color: #fff;
  font-weight: 700;
  font-size: 8px; 
}
@media screen and (min-width: 992px) {
  .page-home #news-notification h5 {
    font-size: 12px; 
  } 
}
@media screen and (max-width: 767px) {
  .page-home #news-notification .excerpt-text {
    max-height: 40px; 
  }
  .page-home #news-notification h5 {
    max-height: 28px; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section .product-description {
    padding: 0;
    margin: 75px 0; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section .caption {
    position: absolute;
    top: 150px; 
  } 
}
.page-home section .caption h1 {
  text-transform: uppercase;
  font-family: 'Elephant-Black';
  font-weight: normal;
  font-style: normal;
  color: #126A6B;
  font-size: calc(48px / 2); 
}
@media screen and (min-width: 992px) {
  .page-home section .caption h1 {
    font-size: calc(80px / 2); 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section .caption h1 {
    margin-bottom: 50px; 
  } 
}
.page-home section .caption h3 {
  color: #00040A;
  font-family: 'PlantinMTPro-LightItalic';
  font-weight: normal;
  font-style: normal;
  font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
  .page-home section .caption h3 {
    font-size: calc(60px / 2); 
  } 
}
.page-home section .caption h3:after {
  content: "\2014";
  display: inline-block; 
}
.page-home section .caption p {
  font-family: 'PlantinMTPro-Regular';
  font-weight: normal;
  font-style: normal;
  font-size: 16px; 
}
@media screen and (min-width: 992px) {
  .page-home section .caption p {
    font-size: 18px; 
  } 
}
.page-home section a.primary-link-button {
  text-decoration: none;
  font-size: 16px; 
}
@media screen and (min-width: 992px) {
  .page-home section a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}
.page-home section h1 {
  font-family: 'Elephant-Medium';
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  color: #00040A;
  font-size: calc(48px / 2); 
}
@media screen and (min-width: 992px) {
  .page-home section h1 {
    font-size: calc(80px / 2); 
  } 
}
.page-home section h2 {
  font-size: 30px;
  display: inline-block;
  width: auto;
  padding-right: 25%; 
}
@media screen and (min-width: 992px) {
  .page-home section h2 {
    font-size: 24px; 
  } 
}
.page-home .section-text-larger {
  display: none; 
}
@media screen and (min-width: 992px) {
  .page-home .section-text-larger {
    display: block; 
  } 
}
.page-home .section-text-smaller {
  margin-top: 50px; 
}
@media screen and (min-width: 992px) {
  .page-home .section-text-smaller {
    display: none; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home .smaller-section-bg {
    display: none; 
  } 
}
.page-home .larger-section-bg {
  display: none; 
}
@media screen and (min-width: 992px) {
  .page-home .larger-section-bg {
    display: block; 
  } 
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .page-home .larger-section-bg {
    margin-top: -40px; 
  } 
}
.page-home .section-image-container {
  position: relative;
  height: 100%; 
}
@media screen and (min-width: 992px) {
  .page-home .section-image-container {
    position: initial; 
  } 
}
.page-home .smaller-section-bg {
  position: relative;
  z-index: -1; 
}
.page-home section.section-1 {
  margin-bottom: 50px;
  position: relative; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 {
    margin-bottom: 150px;
    min-height: 1120px;
    height: 82vh; 
  } 
}
.page-home section.section-1 h1 {
  text-indent: -1em;
  padding-left: 1em; 
}
.page-home section.section-1 a.primary-link-button {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
  text-decoration: none;
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}


.page-home section.section-1 .section-text-larger p {
  font-size: 18px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 .section-text-larger p {
    font-size: 20px; 
  } 
}
.page-home section.section-1 .header-text-area {
  margin-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 .header-text-area {
    margin-bottom: 0; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 .lg-full-sm-half {
    min-height: 1100px; 
  } 
}
.page-home section.section-1 .section-image-container {
  text-align: right;
  padding-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 .section-image-container {
    padding-bottom: 0;
    text-align: left; 
  } 
}
.page-home section.section-1 .section-image-container img {
  width: 55%;
  margin-left: auto;
  position: relative;
  top: 0; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 .section-image-container img {
    position: absolute;
    top: auto;
    left: auto;
    max-width: calc(100% - 30px);
    margin-left: unset;
    width: 100%; 
  } 
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .page-home section.section-1 .section-image-container img {
    margin-left: 60px; 
  } 
}
@media screen and (min-width: 1200px) {
  .page-home section.section-1 .section-image-container img {
    margin-bottom: -50px; 
  } 
}
.page-home section.section-1 .bg {
  background-image: url(/wp-content/themes/boltthreads/dist/images/new-design/home/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0px 100%;
  background-size: cover;
  position: absolute;
  bottom: 0;
  width: calc(100vw - (100vw - 100%) / 2);
  height: 108px;
  left: 48px;
  top: 380px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-1 .bg {
    top: auto;
    height: 125px;
    left: 20%;
    height: 250px; 
  } 
}
.page-home section.section-1 .bg.smaller-section-bg {
  left: 0;
  bottom: 0px;
  top: auto; 
}
.page-home section.section-1 p {
  max-width: 518px; 
}
.page-home section.section-2 h3 {
  margin: 75px auto;
  line-height: 1.5;
  font-size: 18px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-2 h3 {
    font-size: 22px; 
  } 
}
.page-home section.section-3 {
  min-height: 600px;
  position: relative;
  margin-bottom: 0px;
  padding-top: calc(148px + 50px); 
}
@media screen and (min-width: 992px) {
  .page-home section.section-3 {
    padding-top: 400px;
    margin-bottom: 50px; 
  } 
}
.page-home section.section-3 .button-container {
  display: none; 
}
.page-home section.section-3 .bg {
  background-image: url(/wp-content/themes/boltthreads/dist/images/new-design/home/office.jpg);
  background-repeat: no-repeat;
  background-position: 0 50%;
  position: absolute;
  top: 0;
  width: calc(100vw - (100vw - 100%) / 2);
  background-size: cover;
  height: 148px;
  right: 48px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-3 .bg {
    right: 0%;
    height: 350px; 
  } 
}
.page-home section.section-3 .row {
  position: relative;
  margin-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-3 .row {
    margin-bottom: inherit; 
  } 
}
.page-home section.section-3 .row .more-articles {
  display: none !important; 
}
@media screen and (min-width: 992px) {
  .page-home section.section-3 .row .more-articles {
    position: absolute;
    display: block !important;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px; 
  } 
}
.page-home .article {
  margin: 50px 0 25px;
  width: 90%; 
}
@media screen and (min-width: 992px) {
  .page-home .article {
    display: none;
    margin: 75px 0 0 0; 
  } 
}
.page-home .article .logo {
  height: 32px;
  margin-bottom: 25px; 
}
.page-home .article .logo img {
  max-height: 32px;
  max-width: 80%; 
}
.page-home .article .headline h3 {
  font-family: 'PlantinMTPro-SemiboldIt';
  font-size: 16px;
  margin-top: 0;
  line-height: 1.5em; 
}
@media screen and (min-width: 992px) {
  .page-home .article .headline h3 {
    margin-top: 20px;
    font-size: 20px; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home .article .headline {
    overflow: hidden;
    text-overflow: ellipsis; 
  } 
}
.page-home .article .headline a {
  font-weight: normal;
  color: #126A6B;
  text-decoration: underline; 
}
.page-home .article .button-container a.btn {
  padding: 8px 12px;
  border-radius: 0px;
  border: 1px solid #333;
  color: #333;
  font-size: 10px; 
}
@media screen and (min-width: 992px) {
  .page-home .article .button-container a.btn {
    font-size: 15px; 
  } 
}
.page-home .article .button-container a.btn:hover {
  text-decoration: none; 
}
.page-home section.mylo {
  position: relative;
  margin-bottom: 0; 
}
.page-home section.mylo h1 {
  font-family: 'Elephant-Medium';
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  color: #00040A;
  font-size: calc(48px / 2);
  margin-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo h1 {
    font-size: calc(80px / 2); 
  } 
}
.page-home section.mylo a.primary-link-button {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo {
    min-height: 750px;
    margin-bottom: 150px; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo .sm-full {
    height: 55vh; 
  } 
}
.page-home section.mylo .bg {
  background-image: url(/wp-content/themes/boltthreads/dist/images/new-design/home/mylo-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0px 50%;
  position: absolute;
  left: 20%;
  bottom: 0;
  height: 200px;
  width: calc(100vw - (100vw - 100%) / 2);
  background-size: cover; 
}
.page-home section.mylo .bg.smaller-section-bg {
  left: 0;
  height: 105px;
  bottom: -25px;
  top: auto; 
}
.page-home section.mylo .product-description {
  bottom: 25%;
  right: 40px; 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo .product-description {
    bottom: 3%;
    padding-bottom: 50px; 
  } 
}
.page-home section.mylo p {
  max-width: 470px; 
}
.page-home section.mylo .section-image-container {
  margin-bottom: 100px;
  height: 100%; 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo .section-image-container {
    margin-bottom: 0; 
  } 
}
.page-home section.mylo .product-img {
  position: relative;
  width: 255px;
  margin-left: auto;
  margin-bottom: 25px; 
}
@media screen and (max-width: 991px) {
  .page-home section.mylo .product-img {
    display: block; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.mylo .product-img {
    position: absolute;
    margin-bottom: 0;
    left: 14%;
    max-width: 100%;
    width: calc(100% + 30px); 
  } 
}
.page-home #mylo-section {
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; 
}
@media screen and (min-width: 992px) {
  .page-home #mylo-section {
    margin-top: 175px;
    margin-bottom: 175px; 
  } 
}
.page-home #mylo-section .product-description {
  background: transparent; 
}
.page-home #mylo-section:before {
  left: -100vw;
  right: -100vw;
  position: absolute;
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  background: #FFF;
  -webkit-transition: all 1s;
  transition: all 1s; 
}
@media screen and (min-width: 992px) {
  .page-home #mylo-section:before {
    top: -125px;
    bottom: -75px; 
  } 
}
.page-home #mylo-section.mylo-highlight:before {
  background: #605D63; 
}
.page-home #mylo-section.mylo-highlight h1, .page-home #mylo-section.mylo-highlight h2, .page-home #mylo-section.mylo-highlight h3, .page-home #mylo-section.mylo-highlight h4, .page-home #mylo-section.mylo-highlight h5, .page-home #mylo-section.mylo-highlight p {
  color: #FFF; 
}
.page-home #mylo-section.mylo-highlight .headline a {
  color: #FFF; 
}
.page-home #mylo-section.mylo-highlight a.primary-link-button {
  background: #FFF;
  color: #00040A; 
}

.page-home #mylo-section.mylo-highlight a {
  color: inherit;
}

.page-home #mylo-section.mylo-highlight .smaller-section-bg {
  z-index: 0; 
}
.page-home section.microsilk {
  position: relative;
  margin-bottom: 0px; 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk {
    min-height: 800px;
    height: 60vh;
    margin-bottom: 150px; 
  } 
}
.page-home section.microsilk h1 {
  font-family: 'Elephant-Medium';
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  color: #00040A;
  font-size: calc(48px / 2);
  margin-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk h1 {
    font-size: calc(80px / 2); 
  } 
}
.page-home section.microsilk a.primary-link-button {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk .sm-full {
    height: 55vh; 
  } 
}
.page-home section.microsilk .bg {
  background-image: url(/wp-content/themes/boltthreads/dist/images/new-design/home/microsilk-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0px 50%;
  position: absolute;
  right: 20%;
  bottom: 40px;
  height: 200px;
  width: calc(100vw - (100vw - 100%) / 2);
  background-size: cover; 
}
.page-home section.microsilk .bg.smaller-section-bg {
  left: 0;
  height: 105px;
  bottom: -25px;
  top: auto; 
}
.page-home section.microsilk .product-description {
  bottom: 25%;
  left: 40px; 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk .product-description {
    bottom: 3%; 
  } 
}
.page-home section.microsilk .section-image-container {
  margin-bottom: 100px;
  height: 100%; 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk .section-image-container {
    margin-bottom: 0; 
  } 
}
.page-home section.microsilk .product-img {
  position: relative;
  width: 255px;
  margin-left: auto;
  margin-bottom: 25px; 
}
@media screen and (max-width: 991px) {
  .page-home section.microsilk .product-img {
    display: block; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.microsilk .product-img {
    position: absolute;
    width: calc(100% + 30px);
    margin-left: auto;
    display: inherit;
    display: inline-block;
    right: 12%; 
  } 
}
.page-home section.microsilk p {
  max-width: 480px; 
}
.page-home section.silk-protein {
  position: relative;
  margin-bottom: 0; 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein {
    min-height: 800px;
    height: 60vh;
    margin-bottom: 150px; 
  } 
}
.page-home section.silk-protein h1 {
  font-family: 'Elephant-Medium';
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  color: #00040A;
  font-size: calc(48px / 2);
  margin-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein h1 {
    font-size: calc(80px / 2); 
  } 
}
.page-home section.silk-protein a.primary-link-button {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein .sm-full {
    height: 55vh; 
  } 
}
.page-home section.silk-protein .bg {
  background-image: url(/wp-content/themes/boltthreads/dist/images/new-design/home/silk-protein-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0px 25%;
  position: absolute;
  left: 20%;
  bottom: 0;
  height: 200px;
  width: calc(100vw - (100vw - 100%) / 2);
  background-size: cover; 
}
.page-home section.silk-protein .bg.smaller-section-bg {
  left: 0;
  height: 105px;
  bottom: -25px;
  top: auto; 
}
.page-home section.silk-protein .product-description {
  bottom: 25%;
  right: 40px; 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein .product-description {
    bottom: 3%;
    padding-bottom: 50px; 
  } 
}
.page-home section.silk-protein p {
  max-width: 430px; 
}
.page-home section.silk-protein .section-image-container {
  margin-bottom: 100px;
  height: 100%; 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein .section-image-container {
    margin-bottom: 0; 
  } 
}
.page-home section.silk-protein .product-img {
  position: relative;
  width: 255px;
  margin-left: auto;
  margin-bottom: 25px; 
}
@media screen and (max-width: 991px) {
  .page-home section.silk-protein .product-img {
    display: block; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.silk-protein .product-img {
    position: absolute;
    left: 12%;
    margin-bottom: 0;
    margin-left: 0;
    width: calc(100% + 30px); 
  } 
}
.page-home section.environmental-mission {
  position: relative;
  margin-bottom: 0px; 
}
.page-home section.environmental-mission h3 {
  color: #00040A;
  font-family: 'PlantinMTPro-LightItalic';
  font-weight: normal;
  font-style: normal;
  font-size: calc(36px / 2);
  text-transform: none; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission h3 {
    font-size: calc(60px / 2); 
  } 
}
.page-home section.environmental-mission h3:after {
  content: "\2014";
  display: inline-block; 
}
.page-home section.environmental-mission h1 {
  font-family: 'Elephant-Black';
  font-weight: normal;
  font-style: normal;
  color: #126A6B;
  font-size: calc(48px / 2);
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 25px; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission h1 {
    font-size: calc(80px / 2); 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission h1 {
    max-width: 520px; 
  } 
}
.page-home section.environmental-mission a.primary-link-button {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}
.page-home section.environmental-mission > .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
}
.page-home section.environmental-mission .lg-full-sm-half {
  min-height: 100px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
}
.page-home section.environmental-mission .lg-full-sm-half .zoom-out-image {
  margin-left: auto; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .lg-full-sm-half .zoom-out-image {
    margin-left: -60px; 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .lg-full-sm-half {
    margin-bottom: 75px;
    min-height: 100px; 
  } 
}
.page-home section.environmental-mission img {
  max-width: 100%; 
}
@media screen and (max-width: 991px) {
  .page-home section.environmental-mission .zoom-out-image {
    width: 345px;
    right: -48px;
    position: relative;
    margin-left: auto;
    height: 319px !important;
    padding: 0; 
  } 
}
.page-home section.environmental-mission .environmental-statement {
  padding-left: 0px; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .environmental-statement {
    padding-left: 0px; 
  } 
}
.page-home section.environmental-mission .from-to-div-container {
  margin-top: 25px;
  margin-bottom: 75px; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .from-to-div-container {
    margin-top: 0;
    margin-bottom: 150px; 
  } 
}
.page-home section.environmental-mission .from-to-div {
  position: relative;
  text-align: center; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .from-to-div {
    min-height: 130px; 
  } 
}
.page-home section.environmental-mission .from-div p {
  color: #592237;
  font-family: Elephant-Medium;
  text-transform: uppercase; 
}
.page-home section.environmental-mission .to-div p {
  color: #126A6B;
  font-family: Elephant-Medium;
  text-transform: uppercase; 
}
.page-home section.environmental-mission .from-to-div strong {
  white-space: nowrap;
  font-weight: normal;
  font-family: PlantinMTPro-LightItalic;
  color: #00040A;
  text-transform: none; 
}
.page-home section.environmental-mission .from-to-div p {
  line-height: 1.5;
  max-width: inherit;
  margin: 0 auto 5px;
  text-align: center; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .from-to-div p {
    display: none; 
  } 
}
.page-home section.environmental-mission .from-to-div .from-to-arrow {
  margin: 0 auto;
  display: inline-block;
  padding: 50px 0;
  max-width: 59px; 
}
@media screen and (min-width: 992px) {
  .page-home section.environmental-mission .from-to-div .from-to-arrow {
    position: absolute;
    padding: 25px 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); 
  } 
}
.page-home section.about-us {
  min-height: 600px;
  position: relative;
  margin-bottom: 50px; 
}
@media screen and (min-width: 992px) {
  .page-home section.about-us {
    margin-bottom: 0; 
  } 
}
.page-home section.about-us h3 {
  color: #00040A;
  font-family: 'PlantinMTPro-LightItalic';
  font-weight: normal;
  font-style: normal;
  font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
  .page-home section.about-us h3 {
    font-size: calc(60px / 2); 
  } 
}
.page-home section.about-us h3:after {
  content: "\2014";
  display: inline-block; 
}
.page-home section.about-us h1 {
  font-family: 'Elephant-Black';
  font-weight: normal;
  font-style: normal;
  color: #126A6B;
  font-size: calc(48px / 2);
  text-indent: -1em;
  padding-left: 1em;
  color: #126A6B;
  margin-bottom: 25px;
  max-width: 360px; 
}
@media screen and (min-width: 992px) {
  .page-home section.about-us h1 {
    font-size: calc(80px / 2); 
  } 
}
@media screen and (min-width: 992px) {
  .page-home section.about-us h1 {
    max-width: 100%; 
  } 
}
.page-home section.about-us a.primary-link-button {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase;
  background-color: #592237;
  text-decoration: none; 
}
@media screen and (min-width: 992px) {
  .page-home section.about-us a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}

.page-home section.about-us > .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
}
.page-home section.about-us .lg-full-sm-half {
  height: auto;
  min-height: auto; 
}
.page-home section.about-us img {
  width: calc(100% + 30px);
  position: relative;
  left: -40px;
  max-width: 400px; 
}

@media screen and (max-width: 991px) {
  .page-home section.about-us img {
    bottom: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .page-home section.about-us img {
    max-width: auto;
    left: 60px;
    max-width: 100%; 
  } 
}
.page-home section.about-us a:hover {
  text-decoration: none; 
}