

.page-silk-protein .parallax-section-1 .caption {
  @media screen and (max-width: 991px) {
    margin-bottom: 25px;     
  } 

  @media screen and (min-width: 992px) {
    overflow: visible;
    
    img {
      transform: translateX(20%);
      z-index: 100;  
    }
  }
}

@media screen and (min-width: 992px) {
  .page-silk-protein .parallax-section-1 .caption {
    margin-top: 40px;
  }
}


.page-silk-protein .parallax-section-1 .section-image--pull-right {
  margin-top: 50px; 
}
@media screen and (max-width: 991px) {
  .page-silk-protein .parallax-section-1 .section-image--pull-right {
    display: none !important; 
  } 
}

.page-silk-protein .parallax-section-1 .row {
  @media screen and (max-width: 991px) {
    margin-bottom: 146px;
  }
}

// Diamond thing
.diamond-thing  {
  display: inline;
  position: absolute;
  bottom: 0;
  left: 0px;
  transform: translate(-55%, 30%);

}

.section-images-smaller {
  position: relative;
  @media screen and (min-width: 992px) {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .page-silk-protein .parallax-section-1 .section-image-smaller {
    width: 294px;
    height: 317px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; 
  } 
}
.page-silk-protein .parallax-section-1 .diamond-image-smaller {
  position: absolute;
  right: 40px;
  width: 333px;
  bottom: -146px;
  max-width: none;

  @media screen and (min-width: 992px) {
    display: none;
  }
}


@media screen and (max-width: 991px) {
  .page-silk-protein .product-img {
    width: 358px; 
  } 
}

// SECTION 2 OK 

.page-silk-protein .parallax-section-2 { 

  @media screen and (min-width: 992px) {
    min-height: 745px;

  }
}

// .page-silk-protein section.section--left-image .sm-full {
//   height: 55vh;

//   @media screen and (min-width: 992px) {
//     min-height: 745px;
//   }
// }


.page-silk-protein .parallax-section-2 h1 {
  max-width: 310px; 
}
@media screen and (min-width: 992px) {
  .page-silk-protein .parallax-section-2 h1 {
    max-width: 400px; 
  } 

  // .page-silk-protein .parallax-section-2.section--left-image {
  //   min-height: 720px;
  // }
}

.page-silk-protein .parallax-section-benefits {

  @media screen and (min-width: 992px) {
    min-height: 740px;
    height: 50vh;
  }
  .sm-full {
    @media screen and (min-width: 992px) {
      min-height: 740px;
      height: 50vh;
    }
  }
  
  ul {
    list-style: none; 
    padding-left: 1em;
    margin-left: 0;
    font-style: italic;
    font-size: 0.9em;
  }

  ul li::before {
    content: "\2022";  
    color: #126A6B; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em; 
  }

  ul li {
    margin-bottom: .75em;

  }
}



// -------------------------
// Request a sample section 
// -------------------------

// -----------------
// Signup Section 
// -----------------

.page-silk-protein #sample-section {
  padding: 50px; 
  background: #000;
  position: relative;
}

.page-silk-protein #sample-section .col-xs-12 {
  display: flex;
  align-items: center;
}

.page-silk-protein #sample-section  h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.page-silk-protein #sample-section:before {
  content: "";
  display: block;
  height: 100%;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  right: -100vw;
  bottom: 0;
}

.page-silk-protein #sample-section .row {
  flex-flow: wrap;
}
@media screen and (min-width: 992px) {
  .page-silk-protein #sample-section .row {
    display: flex;
  }
}


.page-silk-protein #sample-section a.sample-button {
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #FFF;
  color: #000;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}

@media screen and (min-width: 992px) {
 .page-silk-protein #sample-section a.sample-button {
  font-size: calc(44px / 2); 
} 
}

.page-silk-protein #sample-section *, 
.page-silk-protein #sample-section h1, 
.page-silk-protein #sample-section h2, 
.page-silk-protein #sample-section h3, 
.page-silk-protein #sample-section p {
  color: #FFF;
  position: relative; 
}

// .page-silk-protein #sample-section .col-xs-12 {
//   position: relative;
//   padding: 80px 0px 80px 100px; 
// }

.page-silk-protein #sample-section h3 {
  max-width: 550px;
  // font-size: 1.62em; 
}
.page-silk-protein #sample-section h3:after {
  display: none; 
}


// Eighteen B Section 
.page-silk-protein .container.parallax-section-18 {

  @media screen and (min-width: 992px) {
    min-height: 680px;
    height: 40vh;
    margin-bottom: 150px;
  }

  .sm-full {
    @media screen and (min-width: 992px) {
      min-height: 680px;
      height: 40vh;
    }

  }

}






// -------------------------
// Section 3 
// -------------------------

@media screen and (min-width: 992px) {
  .page-silk-protein section.parallax-section-3.section--right-image {
    min-height: 650px;
    height: 45vh;
  }
}

@media screen and (min-width: 992px) {
 .page-silk-protein section.parallax-section-3 .sm-full {
  min-height: 720px;
  height: 49vh;
}
}

@media screen and (min-width: 992px) {
 .page-silk-protein section.parallax-section-3.section--right-image .sm-full {
  min-height: 650px;
}
}

.page-silk-protein .parallax-section-3 h1 {
  max-width: 235px; 
}

@media screen and (min-width: 992px) {
  .page-silk-protein .parallax-section-3 h1 {
    max-width: 330px; 
  } 
}

.page-silk-protein .parallax-section-4 h1 {
  max-width: 300px; 
}
@media screen and (min-width: 992px) {
  .page-silk-protein .parallax-section-4 h1 {
    max-width: 400px; 
  } 
}

.page-silk-protein .meet-section  {
  background: #126A6B;

  .row {
    display: flex;
    flex-wrap:wrap;
  }

  .image-container {
    position: relative;
    .meet-image {
      background-size: cover;
      background-position: 50% 50%;
      max-width: 300%;
      position: relative;
      left: -48px;
      width: calc(100% + 96px);

      @media screen and (min-width: 992px) {
        width: auto;
        position: absolute;
        left: -148px;
        top: 0;
        bottom: 0;
        right: 0;
      }

      img {
        max-width: 300%;
        margin-left: -24px;
        margin-right: -24px;
        visibility: hidden;
        
        @media screen and (min-width: 992px) {
          display: none;
        }
      }
    }
  }

  .meet-text-area {
    color: #FFF;
    padding: 79px 46px;
    
    @media screen and (min-width: 992px) {
      padding-top: 78px;
      padding-bottom: 78px;
      padding-left: 138px;
      padding-right: 0;
    }

    h1, h2, h3, h4, p {
      color: #FFF;      
    }
  }
}

.page-silk-protein  .container.parallax-section-vegamour.section--right-image {
  @media screen and (min-width: 992px) {
    min-height: 635px;
    height: 49vh;
    margin-bottom: 150px;
  }

  @media screen and (min-width: 992px) {
    section.section--right-image .sm-full {
      min-height: 640px;
      height: 40vh;
    }
    .sm-full.section--image--container {
      height: 40vh;
    }

  }
}

.page-silk-protein .spacer {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
    height: 1px;
  }
}

.page-silk-protein {
  // h3 {
  //   margin-bottom: 24px;
  // }

  a.sample-button, a.join-button  {
    margin-top: 24px;
    display: inline-block;
    font-family: 'Elephant-Medium';
    background: #FFF;
    color: #000;
    padding: 7px 25px;
    font-size: calc(24px / 2);
    text-transform: uppercase; 
    &:hover, &:focus {
      text-decoration: none;
    }
    @media screen and (min-width: 992px) {
      font-size: calc(44px / 2); 
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}


