$text: #434343;
$greentext: #99A159;
$bluetext: #576A79;
$mobile-max: 546px;
$tab-min: $mobile-max + 1;
$header-height: 97px;
$header-height-xs: 84px;
$footer-height: 93px;
$footer-height-xs: 189px;
$onepage-width: 993px;
$half-header-height: $header-height / 2;
@import '_bootstrap_vars';

// #wpadminbar { display:none; }

body.logged-in {
	padding-top: 46px; 
}

@media screen and (min-width: 782px) {
	body.logged-in {
		padding-top: 32px; 
	} 
}

p { max-width: 40em; }
* { text-rendering: optimizeLegibility; }
// if text is 20px or bigger, use this
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;

.container-fluid.fluid-padding {
	@media screen and (min-width: $screen-sm-min) {
		padding-left: 50px;
		padding-right: 50px;
	}
	@media screen and (min-width: $screen-md-min) {
		padding-left: 70px;
		padding-right: 70px;
	}
}

body {
	color: $obsidian; 
}

p a {
	color: $obsidian;
	text-decoration: underline; 
}
p a:hover, p a:active, p a:focus {
	color: #000; 
}