.page-about {
  position: relative;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out; }
  @media screen and (min-width: 992px) {
    .page-about .section-image--pull-right {
      margin-top: 50px; } }

@media screen and (max-width: 991px) {
  .page-about .section-image--pull-right {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .page-about .section-image-smaller {
    width: 344px;
    height: 318px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; } }

@media screen and (max-width: 991px) {
  .page-about .header .caption {
    margin-bottom: 0; } }

.page-about .header h1 {
  // max-width: 350px;
  text-indent: 0px;
  padding-left: 0; }
  @media screen and (min-width: 992px) {
    .page-about .header h1 {
      text-indent: -1em;
      padding-left: 1em; } }

.container.locations h1 {
  margin-bottom: 24px; }

.about-global-map {
  margin-top: 75px; }

@media screen and (min-width: 992px) {
  .page-about .info-section h1 {
    max-width: 300px;
    text-indent: -2.3em;
    padding-left: 2.3em; } }

@media screen and (max-width: 991px) {
  .page-about .info-section .caption {
    margin-bottom: 0; } }

.page-about .team_section {
  margin-top: 12em; }

.page-about .team-heading {
  margin-bottom: 50px; }

@media screen and (min-width: 992px) {
  .page-about .team-heading {
    margin-bottom: 100px; } }

.page-about .leadership-item {
  margin-bottom: 8em; }

@media screen and (max-width: 991px) {
  .page-about .leadership-item img {
    width: 200px;
    margin-bottom: 15px; } }

.page-about .leadership-name {
  margin-bottom: 0;
  line-height: normal;
  margin-top: .25em;
  text-transform: uppercase;
  font-family: 'Elephant-Medium';
  font-size: calc(30px / 2); }
  @media screen and (min-width: 992px) {
    .page-about .leadership-name {
      font-size: calc(60px / 2); } }
  .page-about .leadership-name:after {
    content: '';
    display: none; }

.page-about .leadership-title {
  line-height: normal;
  color: #00040A;
  margin-bottom: 1em;
  margin-top: .25em;
  font-family: 'PlantinMTPro-Italic';
  font-size: calc(24px / 2); }
  @media screen and (min-width: 992px) {
    .page-about .leadership-title {
      font-size: calc(44px / 2); } }

.page-about .leadership-bio {
  color: #000; }

@media screen and (min-width: 992px) {
  .page-about .leadership-info-col {
    padding-left: 4em; } }