@media screen and (max-width: 991px) {
  .page-careers .container .caption {
    margin-bottom: 0; 
  } 
}

@media screen and (max-width: 991px) {
  .page-careers .section-image--pull-right {
    display: none !important; 
  } 
}

@media screen and (max-width: 991px) {
  .page-careers .section-image-smaller {
    width: 344px;
    height: 318px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; 
  } 
}

.page-careers section.header {
  position: relative; 
}

.page-careers section.header .lg-full-sm-half {
  min-height: auto;
  height: auto; 
}

.page-careers section.header img {
  width: calc(100% + 60px);
  position: relative; 
}

.page-careers section.section-2 {
  margin-top: 75px;
  margin-bottom: 50px; 
}

.page-careers section.section-2 p {
  margin: 0 auto 25px;
  line-height: 1.5; 
}

.page-careers section.section-3 {
  min-height: auto;
  position: relative; 
}

.page-careers section.section-3 .row {
  position: relative; 
}

.page-careers section.section-3 .row .article {
  margin: 25px 0 25px;
  width: 90%; 
}
@media screen and (min-width: 992px) {
  .page-careers section.section-3 .row .article {
    margin: 25px 0; 
  } 
}

.page-careers section.section-3 .row .article .logo {
  height: 44px; 
}

.page-careers section.section-3 .row .article .logo img {
  max-height: 100%;
  width: auto;
  max-width: 80%; 
}

@media screen and (min-width: 992px) {
  .page-careers section.section-3 .row .article .headline {
    min-height: 70px;
    margin-top: 25px;
    margin-bottom: 25px; 
  } 
}

.page-careers section.section-3 .row .article .headline a {
  font-family: 'PlantinMTPro-SemiboldIt';
  font-weight: normal;
  text-decoration: underline;
  color: #126A6B;
  line-height: 1.5;
  font-size: 16px; 
}
@media screen and (min-width: 992px) {
  .page-careers section.section-3 .row .article .headline a {
    font-size: calc(44px / 2); 
  } 
}

.page-careers section.section-3 .row .article .headline a:hover {
  text-decoration: none;
  border-bottom: 1px solid #575757; 
}

.page-careers a.btn {
  padding: 8px 12px;
  border-radius: 0px;
  border: none;
  color: #000;
  background: #FFF;
  font-size: 12px; 
}

@media screen and (min-width: 992px) {
  .page-careers a.btn {
    font-size: 18px; 
  } 
}

.page-careers a.btn:hover {
  text-decoration: none; 
}

.page-careers section.invest {
  position: relative; 
}
@media screen and (min-width: 1200px) {
  .page-careers section.invest {
    margin-bottom: 100px; 
  } 
}
.page-careers section.invest .lg-full-sm-half, .page-careers section.invest .sm-full {
  height: auto;
  min-height: auto; 
}
@media screen and (max-width: 991px) {
  .page-careers section.invest h1 {
    max-width: 220px; 
  } 
}
@media screen and (min-width: 992px) {
  .page-careers section.invest .caption {
    margin-top: 50px; 
  } 
}
@media screen and (max-width: 991px) {
  .page-careers section.invest .section-image-container {
    margin-bottom: 0; 
  } 
}
@media screen and (max-width: 991px) {
  .page-careers section.invest .section-image--pull-left {
    margin-bottom: 0px;
    display: none !important; 
  } 
}

.page-careers section.employees .row-eq-height {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; 
}

@media screen and (min-width: 992px) {
  .page-careers section.employees .row-eq-height > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 33%; 
  } 
}

.page-careers section.employees .employee-profile {
  width: 100%;
  margin: 0 auto 50px; 
}

@media screen and (min-width: 992px) {
  .page-careers section.employees .employee-profile {
    width: 90%; 
  } 
}

@media screen and (min-width: 992px) {
  .page-careers section.employees .employee-profile {
    width: 80%;
    margin-left: inherit;
    margin-right: inherit;
    margin-bottom: 50px; 
  } 
}

.page-careers section.employees .employee-profile img {
  width: 100%;
  max-width: 250px; 
}

.page-careers section.employees .employee-profile h3 {
  font-family: 'Elephant-Black';
  text-transform: uppercase;
  color: #000000;
  line-height: 1.5em;
  margin-bottom: 0;
  font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
  .page-careers section.employees .employee-profile h3 {
    font-size: calc(60px / 2); 
  } 
}
.page-careers section.employees .employee-profile h3:after {
  display: none;
  content: ""; 
}

@media screen and (min-width: 992px) {
  .page-careers section.employees .employee-profile h3 {
    font-size: 24px; 
  } 
}

.page-careers section.employees .employee-profile h4 {
  font-family: 'PlantinMTPro-Italic';
  color: #00040A;
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.25em;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  .page-careers section.employees .employee-profile h4 {
    font-size: 18px; 
  } 
}

.page-careers section.employees .employee-profile p {
  font-family: 'PlantinMTPro-Regular';
  color: #00040A;
  font-style: normal;
  line-height: 1.5em; 
}

.page-careers section.join-our-team {
  position: relative;
  margin-bottom: 2.55em; 
}

@media screen and (min-width: 992px) {
  .page-careers section.join-our-team {
    margin-bottom: 5em; 
  } 
}

@media screen and (min-width: 992px) {
  .page-careers section.join-our-team .caption {
    margin-top: 50px; 
  } 
}

.page-careers a.btn {
  margin-top: 25px;
  display: inline-block;
  border-radius: 0px;
  font-weight: normal;
  font-family: 'Elephant-Black';
  text-transform: uppercase;
  background: #592237;
  border: none;
  color: #fff;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: calc(24px / 2); 
}
@media screen and (min-width: 992px) {
  .page-careers a.btn {
    font-size: calc(44px / 2); 
  } 
}