
.blog-posts-page-container {
	margin-bottom: 2em;
	font-family: 'PlantinMTPro-Regular'; 
}

.featured-blog-post-image, .blog-post-image {
	width: 100%;
	background-color: #c3c3c3; 
}

.container.container-blog-content img, .container.container-blog-content .wp-caption {
	max-width: 100% !important;
	height: auto;
}

.blog-content-container figcaption {
	font-family: 'PlantinMTPro-Italic'; 
}

.featured-blog-post-container {
	font-family: 'PlantinMTPro-Regular';
	line-height: 1.5;
	margin-top: 1em;
	background: #eee;
	padding-top: 2em;
	font-size: 16px;
	margin-bottom: -26px; 
}
.featured-blog-post-container a {
	width: 100%; 
}
.featured-blog-post-container h2 {
	text-transform: uppercase;
	font-family: 'PlantinMTPro-Regular'; 
}
@media screen and (min-width: 992px) {
	.featured-blog-post-container {
		margin-bottom: -36px; 
	} 
}
.featured-blog-post-container.sticky-blog-post-container {
	background-color: transparent;
	border-top: 1px solid;
	border-bottom: 1px solid;
	margin-bottom: 1em; 
}
.featured-blog-post-container .featured-blog-post-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap; 
}
.featured-blog-post-container .featured-blog-post-row > * {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1; 
}
.featured-blog-post-container .featured-blog-post-row .featured-blog-post-text {
	margin-bottom: 1.5em; 
}
.featured-blog-post-container h1 {
	text-transform: uppercase;
	font-family: 'Elephant-Medium';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-transform: uppercase; 
}
.featured-blog-post-container h3 a {
	font-family: 'Elephant-Medium';
	font-size: calc(30px / 2);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
	.featured-blog-post-container h3 a {
		font-size: calc(60px / 2); 
	} 
}
.featured-blog-post-container .featured-blog-post-image {
	height: 100%; 
}
.featured-blog-post-container .byline {
	color: #666666;
	font-size: 12px; 
}
@media screen and (min-width: 768px) {
	.featured-blog-post-container .byline {
		font-size: 14px; 
	} 
}
.featured-blog-post-container .excerpt-read-more {
	display: block;
	margin-top: 1em;
	font-family: 'PlantinMTPro-Italic';
	text-decoration: underline;
	color: #126A6B; 
}

h1.blog-category-title {
	text-transform: uppercase; 
}

.blog-posts-container {
	line-height: 1.5;
	font-size: 16px; 
}
.blog-posts-container h3 a {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-transform: uppercase;
	font-family: 'Elephant-Medium';
	font-size: calc(30px / 2); 
}
@media screen and (min-width: 992px) {
	.blog-posts-container h3 a {
		font-size: calc(60px / 2); 
	} 
}
.blog-posts-container .byline {
	color: #00040A;
	font-size: .8em; 
}
.blog-posts-container .blog-post-image {
	margin: 15px; 
}
.blog-posts-container .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap; 
}
.blog-posts-container .excerpt-read-more {
	display: block;
	margin-top: 1em;
	font-family: 'PlantinMTPro-Italic';
	text-decoration: underline;
	color: #126A6B; 
}

.blog-post-container {
	line-height: 1.5;
	padding: 1.5em; 
}
@media screen and (min-width: 600px) {
	.blog-post-container {
		margin-bottom: 2em; 
	} 
}
.blog-post-container a {
	width: 100%;
	color: #00040A;
	overflow: hidden; 
}

@media screen and (min-width: 549px) {
	.blog-hero-container {
		margin-top: 2em;
		margin-bottom: 2em;
		padding-bottom: 1.5em; 
	} 
}

.blog-hero-container img {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%); 
}
@media screen and (min-width: 992px) {
	.blog-hero-container img {
		max-width: 50vw; 
	} 
}

.blog-hero-container .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap; 
}

.blog-hero-container .hero-text {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%); 
}
@media screen and (min-width: 549px) {
	.blog-hero-container .hero-text {
		padding-top: 2em;
		padding-bottom: 2em; 
	} 
}
.blog-hero-container .hero-text h3 {
	text-transform: uppercase;
	margin-bottom: 1em;
	font-family: 'Elephant-Black';
	font-weight: normal;
	font-style: normal;
	color: #126A6B;
	font-size: calc(48px / 2);
	text-indent: -1em;
	padding-left: 1em; 
}
@media screen and (min-width: 992px) {
	.blog-hero-container .hero-text h3 {
		font-size: calc(80px / 2); 
	} 
}
.blog-hero-container .hero-text p {
	max-width: 365px; 
}

.blog-hero-container .blog-hero-image-section {
	margin-left: auto; 
}
@media screen and (min-width: 600px) {
	.blog-hero-container .blog-hero-image-section {
		width: 49%; 
	} 
}
@media screen and (max-width: 550px) {
	.blog-hero-container .blog-hero-image-section {
		margin-top: 2em; 
	} 
}

.blog-search-header-container {
	color: #00040A;
	padding-top: 0;
	padding-bottom: 1.5em;
	font-size: 14px; 
}
@media screen and (min-width: 549px) {
	.blog-search-header-container {
		padding-top: 1.5em;
		margin-top: 2em;
		margin-bottom: 2em; 
	} 
}
.blog-search-header-container .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative; 
}
.blog-search-header-container .row > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap; 
}
.blog-search-header-container .blog-posts-category-navigation {
	text-align: center;
	white-space: nowrap;
	margin-right: 220px;
	line-height: 1.5;
	padding-left: 0;
	padding-right: 0;
	position: relative;
	z-index: 20; 
}
@media screen and (max-width: 599px) {
	.blog-search-header-container .blog-posts-category-navigation {
		margin-right: 220px;
		width: 100%;
		text-align: center;
		margin-bottom: 2em;
		margin-top: 2em; 
	} 
}
@media screen and (max-width: 550px) {
	.blog-search-header-container .blog-posts-category-navigation {
		margin-right: 0; 
	} 
}
.blog-search-header-container .blog-posts-category-navigation a {
	font-family: 'PlantinMTPro-SemiboldIt';
	color: #126A6B;
	text-decoration: underline;
	font-style: normal;
	padding-left: 15px;
	padding-right: 15px; 
}
.blog-search-header-container .screen-reader-text {
	display: none; 
}
.blog-search-header-container .search-function {
	text-align: right;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%); 
}
.blog-search-header-container .search-container {
	position: absolute;
	top: 50%;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-align: right; 
}
@media screen and (max-width: 599px) {
	.blog-search-header-container .search-container {
		width: 100%;
		text-align: center; 
	} 
}
@media screen and (max-width: 550px) {
	.blog-search-header-container .search-container {
		position: relative;
		top: 0;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center; 
	} 
}
.blog-search-header-container .search-container label {
	display: block;
	margin-right: 30px;
	margin-bottom: 0; 
}
.blog-search-header-container .search-container input {
	border-radius: 3px;
	padding: 6px 10px;
	border: 1px solid #00040A;
	color: #00040A;
	font-weight: normal;
	font-family: 'PlantinMTPro-Regular'; 
}
.blog-search-header-container .search-container ::-webkit-input-placeholder {
	font-family: 'PlantinMTPro-Italic'; 
}
.blog-search-header-container .search-container ::-moz-placeholder {
	font-family: 'PlantinMTPro-Italic'; 
}
.blog-search-header-container .search-container :-ms-input-placeholder {
	font-family: 'PlantinMTPro-Italic'; 
}
.blog-search-header-container .search-container ::-ms-input-placeholder {
	font-family: 'PlantinMTPro-Italic'; 
}
.blog-search-header-container .search-container ::placeholder {
	font-family: 'PlantinMTPro-Italic'; 
}
.blog-search-header-container .search-container button {
	position: absolute;
	top: 5px;
	right: 0;
	background: none;
	border: none; 
}

.blog-content-container {
	font-family: 'PlantinMTPro-Regular';
	margin-bottom: 8em;
	line-height: 1.5; 
}
.blog-content-container .wp-block-image {
	margin-top: 2em;
	margin-bottom: 2em; 
}
.blog-content-container .blog-title-header .text-center * {
	text-align: center;
	margin-left: auto;
	margin-right: auto; 
}
.blog-content-container .blog-title-header .text-center p {
	line-height: 1.5;
	text-align: center;
	margin-left: auto;
	margin-right: auto; 
}
.blog-content-container .blog-header-image {
	background: gray;
	margin-top: 2em;
	margin-bottom: 4em;
	padding-left: 0;
	padding-right: 0; 
}
.blog-content-container .container-blog-content {
	max-width: 800px;
	line-height: 1.5; 
}

.blog-share-container {
	color: #333;
	margin-top: 2em; 
}
.blog-share-container a {
	color: #333;
	font-size: 28px;
	padding-left: .75em;
	padding-right: .75em; 
}

div.heateor_sss_horizontal_sharing ul.heateor_sss_sharing_ul li {
	padding: 0px 15px !important; 
}

.heateor_sss_sharing_container.heateor_sss_horizontal_sharing {
	margin-top: 2em !important; 
}

.heateor_sss_horizontal_sharing .heateorSssSharing, .heateor_sss_standard_follow_icons_container .heateorSssSharing {
	background-color: #000; 
}

.pagination a {
	color: #126A6B;
	text-decoration: underline;
	margin-left: 10px;
	margin-right: 10px; 
}


.prev-next-container {
	margin-top: 5em; 
}
.prev-next-container a {
	color: #126A6B; 
}
@media screen and (min-width: 600px) {
	.prev-next-container .text-sm-right {
		text-align: right; 
	} 
}

.blog-content-container .aligncenter, .container-blog-content .aligncenter {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.blog-content-container .wp-caption-text {
	font-size: 0.75em;
	max-width: 100%;
}

.blog-content-container, .container-blog-content {
	a {
		color: #000;
		text-decoration: underline;
		font-style: italic;
	}
	
	div, p, li, ul, ol, span {

		a {
			color: #000;
			text-decoration: underline;
		}
	}
}