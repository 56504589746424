@media screen and (max-width: 991px) {
  .page-microsilk .parallax-section-1 .caption {
    margin-bottom: 25px; 
  } 
}

.page-microsilk .parallax-section-1 .section-image--pull-right {
  margin-top: 50px; 
}
@media screen and (max-width: 991px) {
  .page-microsilk .parallax-section-1 .section-image--pull-right {
    display: none !important; 
  } 
}

@media screen and (max-width: 991px) {
  .page-microsilk .parallax-section-1 .section-image-smaller {
    width: 344px;
    height: 318px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; 
  } 
}

@media screen and (max-width: 991px) {
  .page-microsilk .product-img {
    width: 358px; 
  } 
}

.page-microsilk .parallax-section-2 h1 {
  max-width: 310px; 
}
@media screen and (min-width: 992px) {
  .page-microsilk .parallax-section-2 h1 {
    max-width: 420px; 
  } 
}

.page-microsilk .parallax-section-3 h1 {
  max-width: 205px; 
}
@media screen and (min-width: 992px) {
  .page-microsilk .parallax-section-3 h1 {
    max-width: 340px; 
  } 
}

.page-microsilk .parallax-section-4 h1 {
  max-width: 300px; 
}
@media screen and (min-width: 992px) {
  .page-microsilk .parallax-section-4 h1 {
    max-width: 400px; 
  } 
}

.page-microsilk .parallax-section-5 h1 {
  max-width: 300px; 
}
@media screen and (min-width: 992px) {
  .page-microsilk .parallax-section-5 h1 {
    max-width: 480px; 
  } 
}