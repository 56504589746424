.page-sustainability {
  position: relative;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out; 
}
.page-sustainability .sections-container .container {
  margin-top: 0px;
  margin-bottom: 0px; 
}
@media screen and (min-width: 992px) {
  .page-sustainability .sections-container .container {
    margin-top: 0;
    margin-bottom: 0; 
  } 
}
@media screen and (min-width: 992px) {
  .page-sustainability .section-image--pull-right {
    margin-top: 50px; 
  } 
}

@media screen and (max-width: 991px) {
  .page-sustainability .section-image--pull-right {
    display: none !important; 
  } 
}

@media screen and (max-width: 991px) {
  .page-sustainability .section-image-smaller {
    width: 344px;
    height: 435px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; 
  } 
}

@media screen and (max-width: 991px) {
  .page-sustainability .header .caption {
    margin-bottom: 0; 
  } 
}

.page-sustainability .header h1 {
  max-width: 522px;
  text-indent: 0px;
  padding-left: 0; 
}
@media screen and (min-width: 992px) {
  .page-sustainability .header h1 {
    text-indent: -1em;
    padding-left: 1em; 
  } 
}

.page-sustainability .principles_section {
  margin-top: 0;
  margin-bottom: 0; 
}
.page-sustainability .principles_section .icon-container {
  position: relative;
  padding-bottom: 100%; 
}
.page-sustainability .principles_section .icon-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 160px;
  max-height: 100%; 
}

@media screen and (min-width: 992px) {
  .page-sustainability .info-section h1 {
    max-width: 300px;
    text-indent: -2.3em;
    padding-left: 2.3em; 
  } 
}

@media screen and (max-width: 991px) {
  .page-sustainability .info-section .caption {
    margin-bottom: 0; 
  } 
}

.page-sustainability .team_section {
  margin-top: 12em; 
}

.page-sustainability .principles-heading {
  text-transform: uppercase;
  margin-bottom: 50px; 
}

@media screen and (min-width: 992px) {
  .page-sustainability .principles-heading {
    margin-bottom: 100px; 
  } 
}

.page-sustainability .princicple-item {
  margin-bottom: 4em; 
}

@media screen and (max-width: 991px) {
  .page-sustainability .princicple-item img {
    width: 200px;
    margin-bottom: 15px; 
  } 
}

.page-sustainability .princicple-name {
  margin-bottom: 0;
  line-height: normal;
  margin-top: .25em;
  text-transform: uppercase;
  font-family: 'Elephant-Medium';
  font-size: calc(30px / 2); 
}
@media screen and (min-width: 992px) {
  .page-sustainability .princicple-name {
    font-size: calc(60px / 2); 
  } 
}
.page-sustainability .princicple-name:after {
  content: '';
  display: none; 
}

.page-sustainability .princicple-title {
  line-height: normal;
  color: #00040A;
  margin-bottom: 1em;
  margin-top: .25em;
  font-family: 'PlantinMTPro-Italic';
  font-size: calc(24px / 2); 
}
@media screen and (min-width: 992px) {
  .page-sustainability .princicple-title {
    font-size: calc(44px / 2); 
  } 
}

.page-sustainability .princicple-bio {
  color: #000;
  margin-top: 1em; 
}

@media screen and (min-width: 992px) {
  .page-sustainability .princicple-info-col {
    padding-left: 4em; 
  } 
}

.animateOnScroll {
  -webkit-transition: all 1s;
  transition: all 1s;
  opacity: 1;
  top: 50px;
  margin-top: 50px;
  margin-bottom: -50px;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5); 
}

.animated {
  opacity: 1;
  top: 0;
  left: 0;
  margin: 0;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); 
}

.container.materials {
  margin-top: 0;
  margin-bottom: 0; 
}

.materials-header-container h1 {
  text-transform: uppercase;
  margin-bottom: 50px; 
}
@media screen and (min-width: 992px) {
  .materials-header-container h1 {
    margin-bottom: 100px; 
  } 
}

.page-sustainability section.materials .row-eq-height {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; 
}

@media screen and (min-width: 992px) {
  .page-sustainability section.materials .row-eq-height > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 33%; 
  } 
}

.page-sustainability section.materials .material-container {
  width: 100%;
  margin: 0 auto 50px; 
}

@media screen and (min-width: 992px) {
  .page-sustainability section.materials .material-container {
    width: 90%; 
  } 
}

@media screen and (min-width: 992px) {
  .page-sustainability section.materials .material-container {
    width: 80%;
    margin-left: inherit;
    margin-right: inherit;
    margin-bottom: 50px; 
  } 
}

.page-sustainability section.materials .material-container img {
  width: 100%;
  max-width: 250px; 
}

.page-sustainability section.materials .material-container h3 {
  font-family: 'Elephant-Black';
  text-transform: uppercase;
  color: #000000;
  line-height: 1.5em;
  margin-bottom: 0;
  font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
  .page-sustainability section.materials .material-container h3 {
    font-size: calc(60px / 2); 
  } 
}
.page-sustainability section.materials .material-container h3:after {
  display: none;
  content: ""; 
}

@media screen and (min-width: 992px) {
  .page-sustainability section.materials .material-container h3 {
    font-size: 24px; 
  } 
}

.page-sustainability section.materials .material-container h4 {
  font-family: 'PlantinMTPro-Italic';
  color: #00040A;
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.25em;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  .page-sustainability section.materials .material-container h4 {
    font-size: 18px; 
  } 
}

.page-sustainability section.materials .material-container p {
  font-family: 'PlantinMTPro-Regular';
  color: #00040A;
  font-style: normal;
  line-height: 1.5em; 
}

.page-sustainability section.meet-our-director-container {
  position: relative;
  margin-bottom: 100px; 
}

@media screen and (min-width: 992px) {
  .page-sustainability section.meet-our-director-container {
    margin-bottom: 150px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-sustainability section.meet-our-director-container .caption {
    margin-top: 50px; 
  } 
}

@media screen and (min-width: 600px) {
  html .page-sustainability .col-sm-6 {
    width: 49%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; 
  }
  html .page-sustainability .col-sm-6 > * {
    max-width: 100%; 
  } 
}

@media screen and (min-width: 992px) {
  html .page-sustainability .col-md-6 {
    width: 49%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; 
  }
  html .page-sustainability .col-md-6 > * {
    max-width: 100%; 
  } 
}