.sections-container {
  width: 100%;
  max-width: 100vw;
  overflow: hidden; 
}

.sections-container p {
  max-width: 100%; 
}

.sections-container img {
  max-width: 100%; 
}

.sections-container h3 {
  color: #00040A;
  font-family: 'PlantinMTPro-LightItalic';
  font-weight: normal;
  font-style: normal;
  font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
  .sections-container h3 {
    font-size: calc(60px / 2); 
  } 
}
.sections-container h3:after {
  content: "\2014";
  display: inline-block; 
}

.sections-container h1 {
  font-family: 'Elephant-Black';
  font-weight: normal;
  font-style: normal;
  color: #126A6B;
  font-size: calc(48px / 2);
  text-indent: -1em;
  padding-left: 1em; 
}
@media screen and (min-width: 992px) {
  .sections-container h1 {
    font-size: calc(80px / 2); 
  } 
}

.sections-container .container {
  margin-top: 75px;
  margin-bottom: 75px; 
}

@media screen and (min-width: 992px) {
  .sections-container .container {
    margin-top: 100px;
    margin-bottom: 100px; 
  } 
}

.sections-container .container.header {
  margin-top: 0px; 
}

@media screen and (min-width: 992px) {
  .sections-container .container.header {
    margin-top: 50px; 
  } 
}

.sections-container .container .caption {
  max-width: 100%;
  overflow: hidden; 
}

@media screen and (min-width: 992px) {
  .sections-container .container .caption {
    margin-top: 100px; 
  } 
}

.sections-container .caption, .sections-container .product-description {
  margin-bottom: 50px; 
}

.sections-container .caption h1, .sections-container .product-description h1 {
  font-family: 'Elephant-Black';
  font-weight: normal;
  font-style: normal;
  color: #126A6B;
  font-size: calc(48px / 2);
  margin-bottom: 25px;
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .sections-container .caption h1, .sections-container .product-description h1 {
    font-size: calc(80px / 2); 
  } 
}

.sections-container .caption h3, .sections-container .product-description h3 {
  color: #00040A;
  font-family: 'PlantinMTPro-LightItalic';
  font-weight: normal;
  font-style: normal;
  font-size: calc(36px / 2);
  margin-bottom: 25px;
  text-transform: none; 
}
@media screen and (min-width: 992px) {
  .sections-container .caption h3, .sections-container .product-description h3 {
    font-size: calc(60px / 2); 
  } 
}
.sections-container .caption h3:after, .sections-container .product-description h3:after {
  content: "\2014";
  display: inline-block; 
}

.sections-container .caption p, .sections-container .product-description p {
  color: #00040A;
  line-height: 1.5;
  max-width: 90%; 
}

.sections-container .section-text-larger {
  display: none; 
}

@media screen and (min-width: 992px) {
  .sections-container .section-text-larger {
    display: block; 
  } 
}

.sections-container .section-text-smaller {
  margin-top: 50px; 
}

@media screen and (min-width: 992px) {
  .sections-container .section-text-smaller {
    display: none; 
  } 
}

.sections-container section.section--right-image .smaller-section-bg, .sections-container section.section--left-image .smaller-section-bg {
  position: absolute;
  bottom: -45px;
  left: 30px;
  height: 105px; 
}

@media screen and (min-width: 992px) {
  .sections-container section.section--right-image .smaller-section-bg, .sections-container section.section--left-image .smaller-section-bg {
    display: none; 
  } 
}

.sections-container .larger-section-bg {
  display: none; 
}

@media screen and (min-width: 992px) {
  .sections-container .larger-section-bg {
    display: block; 
  } 
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .sections-container .larger-section-bg {
    margin-top: -40px; 
  } 
}

.sections-container .section-image-container {
  position: relative;
  height: 100%;
  margin-bottom: 90px; 
}

@media screen and (min-width: 992px) {
  .sections-container .section-image-container {
    position: initial; 
  } 
}

.sections-container .smaller-section-bg {
  position: relative;
  z-index: -1; 
}

.sections-container .btn {
  text-decoration: none; 
}

.sections-container .section-image--pull-right {
  position: relative;
  width: 100%; 
}

@media screen and (min-width: 992px) {
  .sections-container .section-image--pull-right {
    width: calc(100% + 60px);
    right: -60px; 
  } 
}

.sections-container .section-image--pull-left {
  position: relative;
  width: 100%; 
}

@media screen and (min-width: 992px) {
  .sections-container .section-image--pull-left {
    width: calc(100% + 60px);
    left: -60px; 
  } 
}

.timeline-section {
  margin-top: 10em;
  margin-bottom: 2.5em; 
}

.faq-section {
  margin-top: 10em;
  margin-bottom: 10em; 
}

.faq-section h1 {
  font-size: 22px;
  margin-bottom: 2em;
  text-transform: uppercase; 
}

@media screen and (min-width: 992px) {
  .faq-section h1 {
    font-size: 36px; 
  } 
}

.faq-container {
  padding-left: 60px;
  margin-bottom: 4em; 
}

.faq-arrow {
  position: absolute;
  left: -35px;
  top: 0; 
}

.faq-arrow svg {
  width: 25px;
  height: 25px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all .5s;
  transition: all .5s; 
}

.faq-arrow svg * {
  fill: #c5c6c8; 
}

.faq-active .faq-arrow svg {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); 
}

.faq-question {
  position: relative;
  margin-bottom: .25em;
  cursor: pointer;
  font-family: 'PlantinMTPro-Italic';
  font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
  .faq-question {
    font-size: calc(60px / 2); 
  } 
}

.faq-answer {
  font-family: 'PlantinMTPro-Regular';
  display: none;
  max-width: 800px; 
}

.section-padding {
  margin-top: 20em; 
}

section.section--left-image {
  position: relative;
  min-height: auto;
  height: auto; 
}

@media screen and (min-width: 992px) {
  section.section--left-image {
    min-height: 650px;
    height: 60vh;
    margin-bottom: 150px; 
  } 
}

section.section--left-image .sm-full {
  min-height: auto; 
}

@media screen and (min-width: 992px) {
  section.section--left-image .sm-full {
    min-height: 600px; 
  } 
}

section.section--left-image .bg {
  background-repeat: no-repeat;
  background-position: 0px 50%;
  position: absolute;
  right: 20%;
  bottom: 0;
  height: 200px;
  width: calc(100vw - (100vw - 100%) / 2);
  background-size: cover; 
}

section.section--left-image .product-description {
  padding-top: 0;
  bottom: 25%;
  left: 40px; 
}

@media screen and (max-width: 991px) {
  section.section--left-image .product-description {
    margin-bottom: 0;
    padding-bottom: 0; 
  } 
}

@media screen and (min-width: 992px) {
  section.section--left-image .product-description {
    bottom: 3%; 
  } 
}

section.section--left-image .product-img {
  margin-left: auto;
  display: inherit;
  max-width: calc(100% - 12%); 
}

@media screen and (max-width: 991px) {
  section.section--left-image .product-img {
    margin-left: auto;
    display: block;
    position: relative;
    left: 30px; 
  } 
}

@media screen and (min-width: 992px) {
  section.section--left-image .product-img {
    display: inline-block;
    margin-left: inherit;
    right: 12%; 
  } 
}

section.section--right-image {
  min-height: auto;
  height: auto;
  position: relative; 
}

@media screen and (min-width: 992px) {
  section.section--right-image {
    min-height: 650px;
    height: 60vh;
    margin-bottom: 150px; 
  } 
}

section.section--right-image .sm-full {
  min-height: auto; 
}

@media screen and (min-width: 992px) {
  section.section--right-image .sm-full {
    min-height: 600px; 
  } 
}

section.section--right-image .bg {
  background-image: url(/wp-content/themes/boltthreads/dist/images/new-design/home/silk-protein-bg.jpg);
  background-repeat: no-repeat;
  background-position: 0px 25%;
  position: absolute;
  left: 20%;
  bottom: 0;
  height: 200px;
  width: calc(100vw - (100vw - 100%) / 2);
  background-size: cover; 
}

section.section--right-image .product-description {
  padding-top: 0;
  bottom: 25%;
  right: 40px; 
}

@media screen and (max-width: 991px) {
  section.section--right-image .product-description {
    margin-bottom: 0;
    padding-bottom: 0; 
  } 
}

@media screen and (min-width: 992px) {
  section.section--right-image .product-description {
    bottom: 3%; 
  } 
}

section.section--right-image .product-img {
  left: 12%;
  max-width: calc(100% - 12%); 
}

@media screen and (max-width: 991px) {
  section.section--right-image .product-img {
    margin-left: auto;
    display: block;
    position: relative;
    left: 30px; 
  } 
}

.section-image-smaller {
  display: block; 
}

@media screen and (min-width: 992px) {
  .section-image-smaller {
    display: none; 
  } 
}

.page-general {

  a {
    color: #126A6B;
    text-decoration: underline;
  }

  p {
    max-width: 100%;
  }


  .patent-name {
    font-family: 'Elephant-Black';
    text-transform: uppercase;
    margin-right: 2em;
  }

  .patent-sup {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 0.75em;
    position: relative;
    top: -0.35em;
  }

}