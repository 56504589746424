.page-contact .body-main {
  margin-bottom: 50px; 
}

@media screen and (min-width: 992px) {
  .page-contact .body-main {
    padding-bottom: 200px; 
  } 
}

.page-contact .sections-container .container {
  margin-top: 0; 
}

@media screen and (min-width: 992px) {
  .page-contact .sections-container .container {
    margin-top: 50px; 
  } 
}

.page-contact .contact-info {
  position: relative;
  margin-left: auto;
  margin-right: auto; 
}
.page-contact .contact-info h3 {
  font-family: 'Elephant-Black';
  font-weight: normal;
  font-style: normal;
  color: #126A6B;
  font-size: calc(48px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-contact .contact-info h3 {
    font-size: calc(80px / 2); 
  } 
}
.page-contact .contact-info h3:after {
  display: none;
  content: ''; 
}

.page-contact .contact-info p {
  margin-top: 1em;
  line-height: 1.5;
  margin-bottom: 1em; 
}

.page-contact .contact-info p.disclaimer {
  font-size: .7em;
  line-height: 1.5em;
  max-width: 100%;
  margin-top: 25px; 
}

.page-contact .contact-info a {
  text-decoration: underline;
  font-family: 'PlantinMTPro-Regular';
  color: #126A6B; 
}




// ****************************************************************************************************
// ****** new contact form stylings *******************************************************************
// ****************************************************************************************************

.page-contact .contact-info {
  input, button, select, textarea {
    width: 100%;
  }
}

.page-contact .contact-info-sub-head {
  display: block;
  padding-top: 1em;
}

.page-contact .additional-info {
  font-size: 0.85em;
  font-style: italic;
}

.page-contact .contact-info {
  // background: #126A6B;

  .sections-container {
    min-height: 100%;
    display: flex;
  }
  .sections-container .container {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
  }
  .sections-container .container .wpcf7 {
    display: flex;
    align-self: center;
  }

  *, h1, h2, h3, h4, p {
    color: #000;
    font-weight: normal;
  }

  h3:after {
    display: none;
  }

  h1 {
    font-family: 'Elephant-Black';
    font-weight: normal;
    font-style: normal;
    color: #126A6B;
    font-size: calc(48px / 2);
    text-indent: -1em;
    padding-left: 1em; 
    text-transform: uppercase;
  }

  @media screen and (min-width: 992px) {
    h1 {
      font-size: calc(80px / 2); 
    } 
  }

  div {
    font-family: 'Elephant-Light';
  }

  .elephant {
    font-family: 'Elephant-Light';
  }

  .info-note {
    font-family: 'PlantinMTPro-Italic';
    font-size: 0.85em;
  }

  .row {
    margin-bottom: 1em;
  }

  .required {
    color: #F47543;
  }


  .required-marker {
    color: #F47543;
    font-weight: bold;

  }

  .requiredCheckbox .wpcf7-list-item-label:after {
    content: "*";
    color: #F47543;
    font-weight: bold;
  }

  label {
    width: 100%;
    max-width: 100%;
    font-family: 'Elephant-Light';
    display: inline-block;
    margin-top: 1em;
  }
  input, textarea {
    width: 100%;
    color: #000;
    background: transparent;
    margin-top: 0.15em;
    padding: 0.5em;
    border: 1px solid #000;
  }

  #job-function {
    font-family: 'PlantinMTPro-Regular';
    // height: 11.5em;
  }

  select {
    width: 100%;
    color: #000;

    border: 1px solid #000;
    border-radius: 0px;
    padding: 12px 10px 11px 5px;
    position: relative;
    top: -2px;

    // background: transparent;
    @media only screen and (min-width: 768px) {
      background: transparent;
    }

    option {
      color: #000;
      padding: 0.5em;

      &:active, &:focus, &:hover {
        background: #000;
        color: #000;
      }
    }

    &:focus {
      option {
        color: #000;
        padding: 0.5em;

        &:active, &:focus, &:hover {
          background: #000;
          color: #000;
        }
      }

    }
  }

  // select:-internal-list-box option:checked {
  //   background: red;
  // }

  .city-state-row {
    label {
      margin-right: 2em;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  select#area-of-interest {
    font-family: sans-serif;  
  }

  input[type=checkbox] {
    width: auto;
    position: relative;
    // top: 0.2em;
    margin-right: 0.5em;
    font-family: 'PlantinMTPro-Regular';

    * {
      font-family: 'PlantinMTPro-Regular';
    }
  }

  .checkbox-container {
    max-width: 760px;
    .wpcf7-list-item {
      width: 100%;

      @media screen and (min-width: 992px) {
        width: 50%;
      }
    }
  }

  .wpcf7-list-item {

    margin-left: 0;
    padding-right: 0;
    
    @media screen and (min-width: 992px) {
      padding-right: 2em;
    }

    * {
      font-family: 'PlantinMTPro-Regular';
    }
  }

  .wpcf7-list-item label {
    display: flex;
    font-family: 'PlantinMTPro-Regular';
  }

  input.wpcf7-form-control.wpcf7-submit {
    margin-top: 2em;
    border: 0px;
    font-family: 'Elephant-Black';
    width: auto;
    display: inline-block;
    // background: #126A6B;
    background: #000;
    color: #FFF;
    padding: 7px 48px;
    font-size: calc(24px / 2);
    text-transform: uppercase; 
    @media screen and (min-width: 992px) {
      font-size: calc(44px / 2); 
      position: relative;
      top: 50%;
      transform: translateY(-50%) 
    }
  }
  .checkbox-601, .checkbox-602 {

    .wpcf7-validates-as-required .wpcf7-list-item-label:after {
      content: "*";
      color: #F47543;
    }
  }
  .wpcf7-not-valid {

  }
  .wpcf7-not-valid-tip  {
    font-family: 'Elephant-Medium';
    // color: #EEE49F;
    color: #F47543;
    * {
      // color: #EEE49F;
      color: #F47543;
    }
  }

  .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    margin: 0;
    color: #F47543;
    border-color: #F47543;
  }

  .text-link {
    color: #000;
    text-decoration: underline;
    &:hover, &:focus {
      color: #000;
      text-decoration: underline;
    } 
  }

  // Success Styling 
  form.wpcf7-form.sent .row {
    display: none;
  }

  .wpcf7 form.sent .wpcf7-response-output {
    border-color: #46b450;
    font-family: 'PlantinMTPro-Regular';
    font-size: 30px;
    text-align: center;
    border: 0;
    padding: 0;
  }

  .wpcf7 form.sent .wpcf7-response-output:after {
    content: "";
    display: block;
    position: relative;
    background: url(http://boltthreads.staging.wpengine.com/wp-content/uploads/2021/06/BoltThreads_Logo_Primary_RGB_WHITE-1.svg);
    height: 50px;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    margin-top: 48px;
  }
  .policy-terms-links {
    font-family: 'PlantinMTPro-Regular';
    font-style: normal;
  }

  .policy-terms-links a {
    font-style: normal;
  }

}

.page-contact .contact-info .wpcf7 form.invalid .wpcf7-response-output, .page-contact .contact-info .wpcf7 form.unaccepted .wpcf7-response-output {
  border: none;
  font-weight: bold;
  padding-left: 0;
}

