.page-mylo .sections-container .caption, .page-mylo .sections-container .product-description {
  margin-bottom: 0;
  padding: 10px;
  padding-bottom: 0;
  margin-left: -10px; 
}

// .page-mylo .products-section .parallax-section .row {
//   display: flex;
// }

// .page-mylo .products-section .parallax-section .section--left-image .col-md-6 {
//   order: 2;
//   background-color: red;
// }

.page-mylo h1 {
  text-transform: uppercase;
}

.page-mylo h4 {
  margin-top: 1em;
  font-family: 'PlantinMTPro-LightItalic'; 
}

@media screen and (min-width: 992px) {
  .page-mylo .centered-header-section {
    margin-bottom: 75px; 
  } 
}

.page-mylo .parallax-section-1 a.primary-link-button {
  margin-top: .8em;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-1 a.primary-link-button {
    font-size: calc(44px / 2); 
  } 
}

@media screen and (max-width: 991px) {
  .page-mylo .parallax-section-1 .caption {
    margin-bottom: 25px; 
  } 
}

.page-mylo .parallax-section-1 .section-image--pull-right {
  margin-top: 50px; 
}
@media screen and (max-width: 991px) {
  .page-mylo .parallax-section-1 .section-image--pull-right {
    display: none !important; 
  } 
}

@media screen and (max-width: 991px) {
  .page-mylo .parallax-section-1 .section-image-smaller {
    width: 344px;
    height: 318px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; 
  } 
}


.page-mylo .parallax-section-timeline .timeline-section {
  margin-bottom: 0; 
} 


@media screen and (min-width: 992px) {
  .page-mylo section.section--left-image.parallax-section-deep-science .product-img {
    left: 47px; 
  } 
}

.page-mylo .parallax-section-deep-science {
  margin-top: 0; 
} 

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-deep-science {
    margin-bottom: 75px; 
  }
  .page-mylo .parallax-section-deep-science .section-image-container {
    position: relative; 
  }
  .page-mylo .parallax-section-deep-science .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  }
  .page-mylo .parallax-section-deep-science.section--left-image {
    min-height: 890px;
    height: 60vh; 
  }
  .page-mylo .parallax-section-deep-science .sm-full {
    min-height: 890px;
    height: 60vh; 
  } 
}

.page-mylo .supply-section .product-description {
  padding: 10px;
  margin-left: -10px; 
}
.page-mylo .supply-section .product-description .section-text-larger p {
  max-width: 92%; 
}

@media screen and (min-width: 992px) {
  .page-mylo .supply-section .section-image-container {
    position: relative; 
  }
  .page-mylo .supply-section .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  }
  .page-mylo .supply-section.section--right-image {
    min-height: 810px;
    height: 65vh; 
  }
  .page-mylo .supply-section .sm-full {
    min-height: 810px;
    height: 65vh; 
  } 
}

.page-mylo #section-signup {
  padding: 50px; 
}
.page-mylo #section-signup *, .page-mylo #section-signup h1, .page-mylo #section-signup h2, .page-mylo #section-signup h3, .page-mylo #section-signup p {
  color: #FFF;
  position: relative; 
}
.page-mylo #section-signup .col-xs-12 {
  position: relative;
  padding: 80px 0px 80px 100px; 
}
.page-mylo #section-signup .col-xs-12 *, .page-mylo #section-signup .col-xs-12 h1, .page-mylo #section-signup .col-xs-12 h2, .page-mylo #section-signup .col-xs-12 h3, .page-mylo #section-signup .col-xs-12 p {
  color: #FFF; 
}
.page-mylo #section-signup .col-xs-12 h3 {
  font-size: 1.62em; 
}
.page-mylo #section-signup .col-xs-12 h3:after {
  display: none; 
}
.page-mylo #section-signup .col-xs-12:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: -100vw;
  background: #232323; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form {
  font-family: 'PlantinMTPro-LightItalic';
  max-width: 600px;
  margin-top: 40px; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form input.button {
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
}
@media screen and (min-width: 992px) {
  .page-mylo #section-signup #mc-embedded-subscribe-form input.button {
    font-size: calc(44px / 2); 
  } 
}
.page-mylo #section-signup #mc-embedded-subscribe-form label {
  display: none; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email-signup-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
}
@media screen and (min-width: 992px) {
  .page-mylo #section-signup #mc-embedded-subscribe-form .email-signup-form {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; 
  } 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email {
  margin-right: 20px;
  border: 1px solid #FFF;
  background-color: #232323;
  color: #FFF;
  font-family: 'PlantinMTPro-Light';
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 10px;
  padding-left: 10px;
  padding-right: 20px;
  margin-bottom: 20px;
  width: 100%; 
}
@media screen and (min-width: 992px) {
  .page-mylo #section-signup #mc-embedded-subscribe-form .email {
    margin-bottom: 0;
    width: auto; 
  } 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email::-webkit-input-placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email::-moz-placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email:-ms-input-placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email::-ms-input-placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email::placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email:-ms-input-placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form .email::-ms-input-placeholder {
  color: #FFF;
  font-family: 'PlantinMTPro-LightItalic';
  opacity: 1; 
}
.page-mylo #section-signup #mc-embedded-subscribe-form #mc-embedded-subscribe {
  background: #FFF;
  border: none;
  padding-left: 50px;
  padding-right: 50px;
  color: #232323; 
}

.page-mylo #mce-responses {
  margin-top: 20px; 
}

.page-mylo h4 {
  color: #000;
  font-size: 1.62em; 
}
.page-mylo h4:after {
  display: none; 
}


// adidas product section 

@media screen and (min-width: 992px) {
  .page-mylo .parallax-product-2-section {
    min-height: 700px;
  }
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-product-2-section .sm-full {
    min-height: 700px; 
  } 
}


// ---- parallax-product-1-section ----
@media screen and (min-width: 992px) {
  .page-mylo .parallax-product-1-section {
    min-height: 780px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-product-1-section .sm-full {
    min-height: 980px; 
  } 
}

.page-mylo .parallax-product-1-section h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-mylo .parallax-product-1-section h1 {
    max-width: 440px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-product-1-section .section-image-container {
    position: relative; 
  }
  .page-mylo .parallax-product-1-section .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  } 
}

// ---- parallax-section-2 ----
@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-2 {
    min-height: 800px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-2 .sm-full {
    min-height: 800px; 
  } 
}

.page-mylo .parallax-section-2 h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-2 h1 {
    max-width: 440px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-2 .section-image-container {
    position: relative; 
  }
  .page-mylo .parallax-section-2 .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  } 
}


// Section 3 
@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-3 {
    height: 60vh;
    min-height: 650px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-3 .sm-full {
    height: 60vh;
    min-height: 650px; 
  } 
}

.page-mylo .parallax-section-3 h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-3 h1 {
    max-width: 440px; 
  } 
}

// Section 4
@media screen and (min-width: 992px) {  
  section.container.parallax-section.parallax-section-4.section--left-image {
    min-height: 600px;
    height: 46vh;
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-4 .section-image-container {
    position: relative;
    top: -5px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-4 {
    height: 50vh;
    min-height: 570px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-4 .sm-full {
    height: 50vh;
    min-height: 570px; 
  } 
}

.page-mylo .parallax-section-4 h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-4 h1 {
    max-width: 440px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-mylo .parallax-section-4 .section-image-container {
    position: relative;
    top: -5px; 
  } 
}






















