body, html {
	font-family: 'PlantinMTPro-Regular';
	font-weight: normal;
	color: #00040A;
	font-size: 12px;
	font-family: 'PlantinMTPro-Regular';
	font-weight: normal;
	font-style: normal;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; 
}
@media screen and (min-width: 992px) {
	body, html {
		font-size: 18px; 
	} 
}
@media screen and (min-width: 992px) {
	body, html {
		font-size: 18px; 
	} 
}

p {
	font-weight: normal;
	font-size: 16px;
	font-family: 'PlantinMTPro-Regular';
	font-weight: normal;
	font-style: normal;
	font-size: 16px; 
}
@media screen and (min-width: 992px) {
	p {
		font-size: 18px; 
	} 
}
@media screen and (min-width: 992px) {
	p {
		font-size: 18px; 
	} 
}

b,
strong {
	font-family: 'PlantinMTPro-Semibold';
	font-weight: normal; 
}

h1, h2, h3, h4, h5 {
	font-weight: normal;
	color: #126A6B; 
}

h1 {
	font-size: 24px;
	font-family: 'Elephant-Black';
	font-weight: normal;
	font-style: normal;
	color: #126A6B;
	font-size: calc(48px / 2); 
}
@media screen and (min-width: 992px) {
	h1 {
		font-size: 36px; 
	} 
}
@media screen and (min-width: 992px) {
	h1 {
		font-size: calc(80px / 2); 
	} 
}

h2 {
	font-size: 21px;
	font-family: 'Elephant-Black';
	font-weight: normal;
	font-style: normal;
	color: #126A6B;
	font-size: calc(24px / 2); 
}
@media screen and (min-width: 992px) {
	h2 {
		font-size: 30px; 
	} 
}
@media screen and (min-width: 992px) {
	h2 {
		font-size: calc(44px / 2); 
	} 
}

h3 {
	font-size: 16px;
	font-family: 'Elephant-Medium';
	font-weight: normal;
	font-style: normal;
	color: #126A6B;
	font-size: calc(36px / 2); 
}
@media screen and (min-width: 992px) {
	h3 {
		font-size: 24px; 
	} 
}
@media screen and (min-width: 992px) {
	h3 {
		font-size: calc(60px / 2); 
	} 
}

sup.tm {
	font-size: .35em;
	line-height: 1.25em;
	position: relative;
	bottom: 1.5em;
	top: auto; 
}
.no-break {
	white-space: nowrap;
}