nav.site-nav {
  font-family: 'ElephantMedium';
  color: #592237;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  position: relative;
  background: #fff;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%; 
}
nav.site-nav .home {
  display: inline-block;
  margin: 1.5em 0; 
}
nav.site-nav .home img {
  width: 100%;
  position: relative;
  top: 36px;
  width: 128px; 
}
@media screen and (min-width: 992px) {
  nav.site-nav .home img {
    width: 160px; 
  } 
}
@media screen and (max-width: 767px) {
  nav.site-nav .home {
    margin: 1em 0; 
  } 
}
nav.site-nav .bg {
  background: #fff;
  z-index: 9; 
}
nav.site-nav .cheeseburger {
  float: right;
  margin-top: 3.3em;
  background: url("../images/nav.burger.png") 50% 50%/cover;
  width: 30px;
  height: 25px;
  text-indent: -9999em;
  overflow: hidden; 
}
nav.site-nav .cheeseburger.active {
  background-image: url("../images/nav.close.png"); 
}
nav.site-nav nav.main-menu {
  position: absolute;
  padding-right: 15px;
  left: 250px;
  bottom: 1.5em;
  line-height: 1em;
  white-space: nowrap;
  top: -16px; 
}
@media screen and (min-width: 992px) {
  nav.site-nav nav.main-menu {
    top: 0; 
  } 
}
nav.site-nav nav.main-menu a {
  display: inline-block;
  color: #592237;
  text-decoration: none;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  nav.site-nav nav.main-menu a {
    font-size: 18px; 
  } 
}
nav.site-nav nav.main-menu > a.active {
  // border-bottom: 1px solid #dfdfdf; 
}
nav.site-nav nav.main-menu span.active {
  // border-bottom: 1px solid #dfdfdf;
  padding-bottom: 5px; 
}
nav.site-nav nav.main-menu li.active {
  // border-bottom: 1px solid #dfdfdf; 
}
nav.site-nav nav.main-menu > a {
  margin: 0 0 0 1em; 
}
@media screen and (min-width: 1200px) {
  nav.site-nav nav.main-menu > a {
    margin: 0 0 0 2em; 
  } 
}
nav.site-nav nav.main-menu ul.dropdown:not(.active) {
  pointer-events: none; 
}
nav.site-nav nav.main-menu ul.dropdown {
  width: 152px; 
}
nav.site-nav nav.main-menu ul.dropdown:focus-within {
  opacity: 1; 
}
nav.site-nav nav.main-menu-mobile {
  font-family: 'Elephant-Medium';
  position: absolute;
  left: 0;
  top: 84px;
  background: #FFF;
  color: #592237;
  width: 100%;
  margin: 0;
  -webkit-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  transform: translateY(-150%);
  -webkit-transition: -webkit-transform 400ms ease;
  transition: -webkit-transform 400ms ease;
  transition: transform 400ms ease;
  transition: transform 400ms ease, -webkit-transform 400ms ease;
  padding-left: 48px;
  padding-right: 48px; 
}
nav.site-nav nav.main-menu-mobile > a {
  border-bottom: 1px solid #D8D8D8;
  padding: 18px 0px;
  color: #592237;
  line-height: 1em;
  text-decoration: none;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  nav.site-nav nav.main-menu-mobile > a {
    font-size: 18px; 
  } 
}
nav.site-nav nav.main-menu-mobile .misc {
  padding: 18px 15px 13px; 
}
nav.site-nav nav.main-menu-mobile .search-container input {
  border: none;
  background: #F2F2F2 url("../images/nav.search.icon.png") 8px 50% no-repeat;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 4px 4px 4px 35px;
  color: #434343;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  nav.site-nav nav.main-menu-mobile .search-container input {
    font-size: 18px; 
  } 
}
nav.site-nav nav.main-menu-mobile .share {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-top: 12px;
  margin-right: 13px;
  overflow: hidden;
  color: #00040A; 
}
nav.site-nav nav.main-menu-mobile .share .fab {
  font-size: 17px; 
}
@media screen and (min-width: 992px) {
  nav.site-nav nav.main-menu-mobile .share .fab {
    font-size: 25px; 
  } 
}
nav.site-nav nav.main-menu-mobile .share.tw {
  background-image: url("../images/share.twitter.white.png"); 
}
@media screen and (max-width: 767px) {
  nav.site-nav nav.main-menu-mobile.active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); 
  } 
}

.container {
  width: 100%;
  padding-left: 48px;
  padding-right: 48px; 
}
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
    padding-left: 15px;
    padding-right: 15px; 
  } 
}

footer.site-footer {
  border-top: 1px solid #E0E0E0;
  background: #592237;
  position: relative;
  text-align: center;
  color: white;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 50px 0 50px; 
}
footer.site-footer .col-phone-email {
  margin-bottom: 1em; 
}
footer.site-footer .social {
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; 
}
footer.site-footer .social a {
  display: inline-block;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer .social a {
    font-size: 18px; 
  } 
}
footer.site-footer .social a.share {
  width: 25px;
  height: 25px;
  color: white;
  margin: 4px 15px 50px; 
}
footer.site-footer .social a.share .fab {
  font-size: 17px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer .social a.share .fab {
    font-size: 25px; 
  } 
}
footer.site-footer a {
  color: #fff; 
}
footer.site-footer fieldset {
  display: inline-block; 
}
footer.site-footer .title {
  margin-bottom: 4px; 
}
footer.site-footer input {
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer input {
    font-size: 18px; 
  } 
}
footer.site-footer input[type=email] {
  background: #434343;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  width: 200px;
  display: inline-block;
  padding: 6px 10px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer input[type=email] {
    width: 350px;
    margin-right: 40px; 
  } 
}
footer.site-footer input[type=email]::-moz-placeholder {
  color: #a5a5a5 !important; 
}
footer.site-footer input[type=email]:-ms-input-placeholder {
  color: #a5a5a5 !important; 
}
footer.site-footer input[type=email]::-webkit-input-placeholder {
  color: #a5a5a5 !important; 
}
@media screen and (max-width: 767px) {
  footer.site-footer input[type=email] {
    padding: 10px; 
  } 
}
footer.site-footer input[type=submit] {
  background: #000;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #fff;
  padding: 8px 35px;
  font-size: 10px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer input[type=submit] {
    font-size: 14px; 
  } 
}
@media screen and (max-width: 767px) {
  footer.site-footer input[type=submit] {
    padding: 10px; 
  } 
}
footer.site-footer label {
  font-family: 'PlantinMTPro-Italic';
  font-weight: normal;
  display: block;
  margin-bottom: 0px;
  font-size: calc(18px / 2); 
}
@media screen and (min-width: 992px) {
  footer.site-footer label {
    font-size: calc(30px / 2); 
  } 
}
footer.site-footer .copyright {
  color: #FFF;
  margin-top: 0px;
  font-family: 'PlantinMTPro-Italic';
  font-size: 16px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer .copyright {
    font-size: 12px; 
  } 
}
footer.site-footer .copyright a {
  color: #a5a5a5;
  text-decoration: underline; 
}
footer.site-footer .newsletter-thanks {
  margin-top: 10px;
  font-size: 12px; 
}
@media screen and (min-width: 992px) {
  footer.site-footer .newsletter-thanks {
    font-size: 18px; 
  } 
}
@media screen and (max-width: 767px) {
  footer.site-footer form input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; 
  }
  footer.site-footer form > div {
    position: relative; 
  }
  footer.site-footer form input[type="email"] {
    display: block;
    width: 100%; 
  } 
}

#contact-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 990; 
}
@media screen and (max-width: 767px) {
  #contact-modal {
    display: none !important; 
  } 
}
#contact-modal .contact {
  position: relative;
  padding-top: 50px;
  top: 98px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.95);
  font-size: 14px; 
}
@media screen and (min-width: 1200px) {
  #contact-modal .contact {
    font-size: 18px; 
  } 
}
#contact-modal .contact .content {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  padding-left: 80px; 
}
@media screen and (min-width: 1200px) {
  #contact-modal .contact .content {
    left: -20px;
    padding-left: 0; 
  } 
}
#contact-modal .contact .content h3 {
  font-family: 'Elephant-Black';
  text-transform: uppercase; 
}
#contact-modal .contact .content p {
  margin-top: 1em;
  line-height: 1.5;
  margin-bottom: 1em; 
}
#contact-modal .contact .content p.disclaimer {
  font-size: .7em;
  line-height: 1.5em;
  max-width: 100%;
  margin-top: 25px; 
}
#contact-modal .contact a {
  color: #126A6B;
  text-decoration: underline; 
}
#contact-modal .contact .close {
  display: block;
  width: 30px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 50px;
  text-indent: -999999em;
  background: url("../images/nav.close.png") 50% 50%/cover no-repeat;
  opacity: 1;
  overflow: hidden;
  z-index: 100; 
}
@media screen and (min-width: 1200px) {
  #contact-modal .contact .close {
    right: 70px; 
  } 
}
#contact-modal .contact form fieldset {
  display: inline-block; 
}
#contact-modal .contact form .title {
  margin-bottom: 4px; 
}
#contact-modal .contact form input {
  font-size: 8px; 
}
@media screen and (min-width: 992px) {
  #contact-modal .contact form input {
    font-size: 12px; 
  } 
}
#contact-modal .contact form input[type=email] {
  background: #EAEAEA;
  border: none;
  color: #575757;
  width: 200px;
  padding: 6px 10px; 
}
#contact-modal .contact form input[type=email]::-moz-placeholder {
  color: #575757 !important; 
}
#contact-modal .contact form input[type=email]:-ms-input-placeholder {
  color: #575757 !important; 
}
#contact-modal .contact form input[type=email]::-webkit-input-placeholder {
  color: #575757 !important; 
}
@media screen and (min-width: 992px) {
  #contact-modal .contact form input[type=email] {
    width: 300px; 
  } 
}
#contact-modal .contact form input[type=submit] {
  background: #575757;
  border: none;
  color: #fff;
  padding: 6px 10px; 
}
@media screen and (max-width: 767px) {
  #contact-modal .contact form input[type=submit] {
    padding: 10px; 
  } 
}
#contact-modal .contact form label {
  margin-right: 1em;
  margin-top: 3em;
  display: block; 
}
#contact-modal .overlay {
  position: fixed;
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); 
}

.zoom-out-image {
  background-position: 50% 50%;
  -webkit-transition: background-size 250ms linear;
  transition: background-size 250ms linear;
  max-width: 100%;
  height: 0 !important;
  padding-bottom: 137%;
  background-repeat: no-repeat; 
}
@media screen and (min-width: 992px) {
  .zoom-out-image {
    background-size: 110%; 
  }
  .zoom-out-image:hover {
    background-size: 102%; 
  } 
}

.page-general footer.site-footer {
  position: relative; 
}

@media screen and (max-width: 767px) {
  .page-general .content-general {
    padding-top: 84px; 
  } 
}

@media screen and (min-width: 768px) {
  .page-general .content-general {
    padding-top: 97px; 
  } 
}

@media screen and (min-width: 1024px) {
  .parallax-item {
    display: none; 
  } 
}

section sup {
  top: -1.2em;
  font-size: .45em; 
}

section h1 sup, section h2 sup, section h3 sup, section h4 sup, section h5 sup {
  top: -1em;
  font-size: .45em; 
}

section .lg-full-sm-half {
  min-height: auto; 
}
@media screen and (min-width: 992px) {
  section .lg-full-sm-half {
    min-height: 550px; 
  } 
}

@media screen and (min-width: 992px) {
  section .sm-full {
    min-height: 720px;
    height: 65vh; 
  }
  section .lg-full-sm-half {
    height: 70vh;
    min-height: 700px; 
  } 
}

@media screen and (min-width: 992px) {
  section .product-img {
    position: absolute; 
  } 
}

section .product-description {
  // background: rgba(255, 255, 255, 0.7);
  padding: 50px 0;
  max-width: 720px;
  z-index: 4; 
}
section .product-description h2 {
  margin-top: 0;
  font-weight: normal;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 50%;
  border-bottom: 1px solid #4f4f4f; 
}
@media screen and (min-width: 1024px) {
  section .product-description h2 {
    width: 40%; 
  } 
}

.subscribe-input-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 

}