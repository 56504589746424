.page-news {
  position: relative; 
}

@media screen and (min-width: 768px) {
  .page-news .headings {
    opacity: 0;
    float: right; 
  } 
}

@media screen and (max-width: 991px) {
  .page-news .sections-container .container.header {
    margin-top: 25px; 
  } 
}

.page-news section.header h1 {
  margin-bottom: 25px; 
}

@media screen and (min-width: 992px) {
  .page-news section.header h1 {
    margin-bottom: 50px; 
  } 
}

.page-news section.header .lg-full-sm-half {
  min-height: auto;
  height: auto; 
}

.page-news section.header sub {
  display: block;
  position: relative;
  top: 10px;
  line-height: normal; 
}

.page-news section.latest {
  margin-bottom: 75px; 
}

.page-news section.latest h3 {
  font-weight: normal;
  margin-bottom: 25px; 
}

.page-news section.latest .featured-article {
  max-width: 90%;
  margin-bottom: 50px; 
}
@media screen and (min-width: 992px) {
  .page-news section.latest .featured-article {
    margin-bottom: 75px; 
  } 
}

.page-news section.latest .featured-article .featured-image {
  width: 100%;
  background-size: cover;
  height: 225px;
  margin-bottom: 0; 
}
@media screen and (min-width: 992px) {
  .page-news section.latest .featured-article .featured-image {
    margin-bottom: 25px; 
  } 
}

.page-news section.latest .featured-article img.logo {
  height: 32px;
  margin-top: 25px; 
}
@media screen and (min-width: 992px) {
  .page-news section.latest .featured-article img.logo {
    margin-bottom: 25px; 
  } 
}

.page-news section.latest .featured-article img.logo img {
  max-height: 32px;
  max-width: 80%; 
}

.page-news section.latest .featured-article h3 {
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 14px; 
}
@media screen and (min-width: 992px) {
  .page-news section.latest .featured-article h3 {
    font-size: 20px; 
  } 
}

.page-news section.latest .featured-article h3 a {
  font-weight: normal;
  text-decoration: underline;
  line-height: 1.35em;
  font-family: 'PlantinMTPro-SemiboldIt';
  color: #126A6B;
  font-size: 18px; 
}
@media screen and (min-width: 992px) {
  .page-news section.latest .featured-article h3 a {
    font-size: 22px; 
  } 
}

.page-news section.latest .featured-article p {
  font-family: 'PlantinMTPro-Regular';
  line-height: 1.5;
  overflow: hidden; 
}

.page-news section.latest .featured-article .button-container {
  padding: 25px 0; 
}

.page-news section.latest .featured-article .button-container a.btn {
  padding: 8px 12px;
  border-radius: 0px;
  border: 1px solid #333333;
  color: #333333;
  font-size: 11px; 
}
@media screen and (min-width: 992px) {
  .page-news section.latest .featured-article .button-container a.btn {
    font-size: 15px; 
  } 
}

.page-news section.latest .featured-article .button-container a.btn:hover {
  text-decoration: none; 
}

.page-news .news-items {
  margin-top: 10px;
  margin-bottom: 100px; 
}
@media screen and (min-width: 768px) {
  .page-news .news-items {
    padding-top: 30px; 
  } 
}

.page-news .news-items .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; 
}

.page-news .news-items h3 {
  font-weight: normal;
  margin-bottom: 50px; 
}

.page-news .news-items .news-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 75px;
  padding-right: 25px;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; 
}
@media screen and (min-width: 768px) {
  .page-news .news-items .news-item {
    max-width: 50%;
    width: 49%; 
  } 
}
@media screen and (min-width: 992px) {
  .page-news .news-items .news-item {
    max-width: 33.3333333333%;
    width: 33%; 
  } 
}

.page-news .news-items .logo-container {
  width: 33%; 
}

.page-news .news-items .logo-container img {
  width: 100%; 
}

.page-news .news-items .text-container {
  padding-left: 25px;
  width: 66%; 
}

.page-news .news-items img {
  width: 100%;
  max-height: 125px; 
}

.page-news .news-items h4 {
  font-weight: normal;
  line-height: 1.35em;
  margin-top: 0;
  font-family: 'PlantinMTPro-SemiboldIt';
  font-size: 16px; 
}
@media screen and (min-width: 992px) {
  .page-news .news-items h4 {
    font-size: 22px;
    padding-right: 50px; 
  } 
}

.page-news .news-items h4 a {
  color: #126A6B;
  text-decoration: underline; 
}

.page-news .news-items h4 a:hover {
  text-decoration: underline; 
}

.page-news .news-items p {
  font-size: 12px; 
}
@media screen and (min-width: 768px) {
  .page-news .news-items p {
    font-size: 14px; 
  } 
}

.page-news .news-items .byline {
  font-family: 'PlantinMTPro-Italic';
  font-style: normal;
  margin-bottom: 0;
  color: #00040A; 
}

.page-news .resources {
  padding-bottom: 50px; 
}

.page-news .resources h3 {
  color: #00040A; 
}

.page-news .resources ul {
  list-style-image: url(../images/resources.icon.download.png);
  margin-left: 22px;
  padding-left: 0px;
  margin-bottom: 20px; 
}