.page-news-single {
  position: relative; 
}

@media screen and (max-width: 767px) {
  .page-news-single {
    padding-bottom: 200px; 
  } 
}

.page-news-single p, 
.page-news-single ul, 
.page-news-single ol, 
.page-news-single h1, 
.page-news-single h2, 
.page-news-single h3, 
.page-news-single h4, 
.page-news-single h5, 
.page-news-single h6 {
  max-width: 40em; 
}

.page-news-single header.title {
  margin-bottom: 2em;
  border-bottom: 2px solid #E0E0E0; 
}

@media screen and (max-width: 767px) {
  .page-news-single header.title {
    margin-bottom: 1.3em; 
  } 
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .page-news-single header.title {
    border-bottom: 0; 
  }
  .page-news-single header.title .header-content {
    border-bottom: 2px solid #b0b0b0; 
  } 
}

.page-news-single header.title h1 {
  max-width: 22em;
  line-height: 1.4;
  color: #00040A;
  margin-top: 0.75em;
  font-size: 16px; 
}

@media screen and (min-width: 1200px) {
  .page-news-single header.title h1 {
    font-size: 24px; 
  } 
}

.page-news-single header.title .byline {
  font-size: 12px;
  margin-bottom: 1.5em; 
}

@media screen and (min-width: 768px) {
  .page-news-single header.title .byline {
    font-size: 14px; 
  } 
}

@media screen and (min-width: 1200px) {
  .page-news-single header.title .byline {
    font-size: 18px; 
  } 
}

@media screen and (min-width: 768px) {
  .page-news-single header.title h1 {
    margin-top: 1.5em; 
  } 
}

@media screen and (max-width: 991px) {
  .page-news-single .main * {
    font-size: 14px; 
  } 
}

@media screen and (max-width: 767px) {
  .page-news-single .main * {
    font-size: 12px; 
  } 
}

.page-news-single .main ul {
  max-width: 40em;
  padding-left: 18px; 
}

.page-news-single .main p, .page-news-single .main ul {
  margin-bottom: 1.5em; 
}

.page-news-single .main a {
  color: #00040A; 
}

.page-news-single .main .share-icon {
  display: inline-block;
  background: url("../images/careers.icon.share.png") 50% 50%/cover no-repeat;
  width: 30px;
  height: 20px;
  text-indent: -99999em;
  margin-left: 6px;
  overflow: hidden; 
}

.page-news-single .main .download-icon {
  display: inline-block;
  background: url("../images/careers.icon.download.png") 50% 50%/cover no-repeat;
  width: 30px;
  height: 20px;
  text-indent: -99999em;
  margin-left: 6px;
  overflow: hidden; 
}

.page-news-single .main .back {
  margin-top: 3em; 
}