
footer.site-footer {
	border-top: 1px solid #E0E0E0;
	background: #000;
	position: relative;
	text-align: center;
	color: #fff;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	padding: 50px 0; 
}
footer.site-footer .contact {
	text-align: center;
	margin-top: 1em; 
}
@media screen and (min-width: 992px) {
	footer.site-footer .contact {
		text-align: left;
		margin-top: 0; 
	} 
}
footer.site-footer .col-phone-email {
	margin-bottom: 1em; 
}
footer.site-footer .social {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	display: block;
	text-align: left;
	margin-left: -15px;
	margin-top: 2em;
	margin-bottom: 1em;
	text-align: center; 
}
@media screen and (min-width: 992px) {
	footer.site-footer .social {
		margin-top: 1em;
		margin-bottom: 0em;
		text-align: left; 
	} 
}
footer.site-footer .social a {
	display: inline-block;
	font-size: 12px; 
}
@media screen and (min-width: 992px) {
	footer.site-footer .social a {
		font-size: 18px; 
	} 
}
footer.site-footer .social a.share {
	width: 25px;
	height: 25px;
	color: #fff;
	margin: auto; 
}
@media screen and (min-width: 992px) {
	footer.site-footer .social a.share {
		margin: 4px 15px 50px; 
	} 
}
footer.site-footer a {
	color: #fff;
	font-family: 'Elephant-Light';
	text-decoration: none;
	font-style: normal; 
}
footer.site-footer fieldset {
	display: inline-block; 
}
footer.site-footer input {
	font-size: 12px; 
}
footer.site-footer input[type=email] {
	background: #434343;
	border: 1px solid #FFF;
	border-radius: 3px;
	color: #fff;
	width: 200px;
	display: inline-block;
	padding: 6px 10px;
	font-size: 12px;
	height: auto; 
}
@media screen and (min-width: 992px) {
	footer.site-footer input[type=email] {
		font-size: 16px; 
	} 
}
@media screen and (min-width: 992px) {
	footer.site-footer input {
		font-size: 18px; 
	}
	footer.site-footer input[type=email] {
		width: 350px;
		margin-right: 40px; 
	} 
}
footer.site-footer input[type=email]::-moz-placeholder {
	color: #a5a5a5 !important; 
}
footer.site-footer input[type=email]:-ms-input-placeholder {
	color: #a5a5a5 !important; 
}
footer.site-footer input[type=email]::-webkit-input-placeholder {
	color: #a5a5a5 !important; 
}
footer.site-footer .newsletter label {
	margin-bottom: 0; 
}
footer.site-footer .newsletter input[type=email] {
	border-radius: 0px;
	font-family: 'PlantinMTPro-Regular';
	margin-right: 10px; 
}
footer.site-footer .newsletter input[type=email]::-webkit-input-placeholder {
	font-family: 'PlantinMTPro-Italic';
	color: red; 
}
footer.site-footer .newsletter input[type=email]::-moz-placeholder {
	font-family: 'PlantinMTPro-Italic';
	color: red; 
}
footer.site-footer .newsletter input[type=email]:-ms-input-placeholder {
	font-family: 'PlantinMTPro-Italic';
	color: red; 
}
footer.site-footer .newsletter input[type=email]::-ms-input-placeholder {
	font-family: 'PlantinMTPro-Italic';
	color: red; 
}
footer.site-footer .newsletter input[type=email]::placeholder {
	font-family: 'PlantinMTPro-Italic';
	color: red; 
}

#contact-modal .contact form .title,
footer.site-footer .title {
	margin-bottom: 4px; 
}

footer.site-footer .social a.share .fab {
	font-size: 17px; 
}

@media screen and (min-width: 992px) {
	footer.site-footer .social a.share .fab {
		font-size: 25px; 
	} 
}

@media screen and (max-width: 767px) {
	nav.site-nav nav.main-menu-mobile.active {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0); 
	}
	footer.site-footer input[type=email] {
		padding: 10px; 
	} 
}

footer.site-footer input[type=submit] {
	font-family: 'Elephant-Black';
	background: #000;
	border: 3px solid #fff;
	border-radius: 0px;
	color: #fff;
	padding: 4px 5px;
	text-transform: uppercase;
	font-size: 12px;
	padding: 0 10px;
	height: auto; 
}
@media screen and (min-width: 992px) {
	footer.site-footer input[type=submit] {
		font-size: 16px; 
	} 
}

footer.site-footer label {
	font-weight: 400;
	display: block;
	margin-bottom: 40px;
	font-size: 12px; 
}

@media screen and (min-width: 992px) {
	footer.site-footer label {
		font-size: 18px; 
	} 
}

footer.site-footer .copyright {
	color: #FFF;
	margin-top: 0px;
	font-family: 'PlantinMTPro-Italic';
	font-size: 12px; 
}
@media screen and (min-width: 992px) {
	footer.site-footer .copyright {
		font-size: 12px; 
	} 
}

footer.site-footer .copyright a {
	color: #fff;
	text-decoration: underline; 
}

footer.site-footer .newsletter-thanks {
	margin-top: 10px;
	font-size: 12px; 
}

@media screen and (min-width: 992px) {
	footer.site-footer .newsletter-thanks {
		font-size: 18px; 
	} 
}

@media screen and (max-width: 767px) {
	footer.site-footer label {
		position: relative; 
	}
	footer.site-footer form input {
		-webkit-box-sizing: border-box;
		box-sizing: border-box; 
	}
	footer.site-footer fieldset {
		width: 100%; 
	}
	footer.site-footer form > div {
		position: relative; 
	}
	footer.site-footer form input[type="email"] {
		display: block;
		width: 100%; 
	} 
}

#menu-footer-menu {
	display: block;
	width: 100%;
	list-style-type: none;
	text-align: left;
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	-webkit-column-gap: 2em;
	-moz-column-gap: 2em;
	column-gap: 2em;
	padding-left: 0;
	font-family: 'Elephant-Light';
	text-transform: uppercase;
	font-size: calc(24px / 2);
	white-space: nowrap; 
}
@media screen and (min-width: 992px) {
	#menu-footer-menu {
		font-size: calc(36px / 2); 
	} 
}
#menu-footer-menu li {
	margin-left: 0;
	padding-left: 0;
	display: block; 
}

// Cookie Notice Styles
.cookie-notice {
	border-top: 1px solid #126A6B;
	padding-top: 28px;
	padding-bottom: 28px;
	background: #FFF;
	position: relative;
	font-size: 12px;
}

.eu-notice-text {
	padding-top: 9px;
	padding-bottom: 9px;
}

.eu-notice-text a,
.non-eu-notice-text a {
	color: inherit;
	text-decoration: underline;
}

.cookie-notice .container {
	position: relative;
}

.cookie-notice .cookie-container {
	position: relative;
	padding-right: 20px;
}

.cookie-notice .close-button {
	position: absolute;
	right: 10px;
	top: -10px;
	font-family: sans-serif;
	color: #126A6B;
	cursor: pointer;
}

@media screen and (min-width: 1200px) {
	.cookie-notice .close-button {
		right: -10px;
	}
}

.cookie-notice .button {
	padding: 9px 9px 9px 0;
	display: inline-block;
	font-family: 'Elephant-Medium';
	text-transform: uppercase;
	display: flex;
	align-self: center;
	cursor: pointer;
}

.cookie-notice .button.cta {
	padding: 9px 36px;
	color: #FFF;
	background: #000;
}

.button-container {
	text-align: right;
	margin-top: 13px;
	display: flex;
	align-content: flex-end;
	justify-content: flex-end;
}


@media screen and (min-width: 1200px) {
	.button-container {
		margin-top: 0;
	}
}

.lower-notice {
	font-size: 10px;
}

.lower-notice label  {
}

#manage-cookies {
	cursor: pointer;
	margin-right: 13px;
}

#manage-cookies .down-arrow {
	transform: rotate(180deg);
	transition: all .25s;
	margin-left: 5px;
	position: relative;
	top: -1px;
	display: flex;
	align-self: center;
}

#manage-cookies.active .down-arrow {
	transform: rotate(0deg);
}


.cookie-options {
	margin-top: 28px;
}

.checkbox-item {
	display: inline-block;
	margin-right: 13px;
}

.checkbox-item input {
	margin-top: 3px;
	margin-right: 5px;
	position: relative;
	top: 2px;
}

.checkbox-item label {
	white-space: nowrap;
}

.options-header {  
	font-family: 'Elephant-Medium';
	text-transform: uppercase;
	margin-right: 31px;
}

#cookie-options-area {
	display: none;
}

.options-description-titles {
	margin-bottom: 13px;
	margin-top: 13px;
}

@media screen and (min-width: 1200px) {
	.options-description-titles {
		margin-top: 0px;
	}
}

#eu-notice {
	display: none;
	position: fixed;
	z-index: 100;
	left: 0;
	right: 0;
	bottom: 0;
}

#non-eu-notice {
	display: none;
	position: fixed;
	z-index: 100;
	left: 0;
	right: 0;
	bottom: 0;
}

      /*Empty Radio*/
      .empty-radio {
      	background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.793945' y='1.44995' width='11.1625' height='11.1625' rx='0.5' fill='%23126A6B' stroke='%23126A6B'/%3E%3C/svg%3E%0A");
      }

      /* Customize the label (the container) */
      .checkbox-item {
      	display: inline-block;
      	position: relative;
      	padding-left: 20px;
      	cursor: pointer;
      	-webkit-user-select: none;
      	-moz-user-select: none;
      	-ms-user-select: none;
      	user-select: none;
      }

      /* Hide the browser's default checkbox */
      .checkbox-item input {
      	position: absolute;
      	opacity: 0;
      	cursor: pointer;
      	height: 0;
      	width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
      	position: absolute;
      	top: 0;
      	left: 0;
      	height: 15px;
      	width: 15px;
      	background-color: #126A6B;
      }
      .checkbox-item label {
      	cursor: pointer;
      }

      /* On mouse-over, add a grey background color */
      .checkbox-item:hover input ~ .checkmark {
      	background-color: #0a3738;
      }

      /* When the checkbox is checked, add a blue background */
      .checkbox-item input:checked ~ .checkmark {
      	background-color: #126A6B;
      }

      .checkbox-item input#necessary:checked ~ .checkmark {
      	background-color: #676767;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
      	content: "";
      	position: absolute;
      	display: none;
      }

      /* Show the checkmark when checked */
      .checkbox-item input:checked + .checkmark:after {
      	display: block;
      }

      /* Style the checkmark/indicator */
      .checkbox-item input:checked + .checkmark:after {
      	left: 8px;
      	top: -2px;
      	width: 5px;
      	height: 10px;
      	border: solid white;
      	border-width: 0 2px 2px 0;
      	-webkit-transform: rotate(45deg);
      	-ms-transform: rotate(45deg);
      	transform: rotate(45deg) scale(1.5);
      }

      .options-description-titles .options-header {
      	cursor: pointer;
      }

      .options-description-titles .options-header.active {
      	text-decoration: underline;
      }

      .option-descriptions .option-description:not(:first-of-type) {
      	display: none;
      }

      footer .accessibility-notice {
      	max-width: 600px;
      	margin-left: auto;
      	margin-right: auto;
      }
      footer .accessibility-notice a {
      	font-family: inherit;
      	text-decoration: underline;
      }
