#timeline-events {
  display: none; 
}

.roadmap .event .timeline-event-text, .roadmap .event .timeline-event-text p {
  font-family: 'PlantinMTPro-Italic'; 
}

#product-timeline {
  font-size: 12px; 
}

@media screen and (min-width: 992px) {
  #product-timeline {
    font-size: 18px; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event .event {
  top: -27px; 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:before, #product-timeline .roadmap.roadmap--orientation-vertical .roadmap__events__event:after {
  background-color: #606060; 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:before {
  z-index: 1;
  background: #FFF;
  border: 2px solid #606060; 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events:after {
  background-color: #606060;
  width: 2px; 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:after {
  background-color: #606060;
  height: 2px !important;
  display: none; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:after {
    display: block; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd) .event {
  margin-top: 11px;
  left: 0px; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd) .event {
    margin-top: 0;
    left: 0;
    top: -1.5em; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event .event {
  text-align: right; 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event .event .timeline-event-text {
  position: relative;
  font-size: 12px;
  padding-top: 1em; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event .event .timeline-event-text {
    padding-top: 20%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 80px;
    font-size: 18px; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event .event .timeline-event-image {
  width: 35px; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event .event .timeline-event-image {
    position: absolute;
    right: 0px;
    width: 53px; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event {
  text-align: left;
  margin-top: 11px;
  right: 0px; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event {
    right: 0;
    top: -27px; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event .timeline-event-text {
  position: relative;
  font-size: 12px;
  padding-top: 1em; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event .timeline-event-text {
    font-size: 18px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-top: 20%;
    left: 80px; 
  } 
}

#product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event .timeline-event-image {
  width: 35px; 
}

@media screen and (min-width: 992px) {
  #product-timeline.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event .timeline-event-image {
    position: absolute;
    left: 0px;
    width: 53px; 
  } 
}

@media screen and (max-width: 991px) {
  .page-about .timeline-section {
    padding-left: 30px;
    padding-right: 30px; 
  }
  .page-about .timeline-section .roadmap.roadmap--orientation-auto .roadmap__events {
    width: 100%; 
  } 
}

#about-timeline {
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 12px; 
}

@media screen and (min-width: 992px) {
  #about-timeline {
    margin-top: 200px;
    margin-bottom: 300px;
    font-size: 18px; 
  } 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap .roadmap__events:after {
    width: 1px;
    height: calc(100% - 240px); 
  } 
}

#about-timeline .timeline-date {
  font-family: 'Elephant-Black';
  color: #592237;
  font-size: calc(36px / 2);
  max-width: 180px;
  text-align: left;
  margin-right: auto;
  position: relative;
  font-size: 24px; 
}
@media screen and (min-width: 992px) {
  #about-timeline .timeline-date {
    font-size: calc(72px / 2); 
  } 
}

@media screen and (min-width: 992px) {
  #about-timeline .timeline-date {
    margin-left: auto;
    left: 18px;
    font-size: 35px; 
  } 
}

#about-timeline .timeline-event-text {
  font-family: 'PlantinMTPro-Italic';
  width: calc(100% + 30px);
  text-align: left;
  margin-left: 0;
  margin-right: auto;
  position: relative; 
}

@media screen and (min-width: 992px) {
  #about-timeline .timeline-event-text {
    padding-left: 68px; 
  } 
}

#about-timeline .timeline-event-text p {
  margin-bottom: .5em;
  line-height: normal;
  font-size: 14px; 
}

@media screen and (min-width: 992px) {
  #about-timeline .timeline-event-text p {
    margin-bottom: .5em;
    font-size: 16px; 
  } 
}

#about-timeline .timeline-event-image {
  width: 150px; 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:after {
    width: 2px; 
  } 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events:after {
    height: 2px; 
  } 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event {
    min-height: 240px; 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd):after {
  display: none; 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd):after {
    display: block;
    margin-top: -60px; 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event {
  top: -10px; 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event {
    top: -10px;
    right: 0px;
    width: 50%;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    padding-right: 30px; 
  } 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event {
    top: auto;
    bottom: 100%;
    padding-bottom: 75px; 
  } 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event .event__content {
    top: auto;
    bottom: 80px;
    height: 320px; 
  } 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event .timeline-date, #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event .timeline-event-text {
    text-align: right;
    margin-left: auto;
    margin-right: 0; 
  } 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event .timeline-event-text {
    right: 30px; 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event .timeline-event-image {
  position: absolute;
  left: calc(100% + 30px); 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event .timeline-event-image {
    padding-bottom: 10px;
    bottom: 60px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even):after {
  display: none; 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even):after {
    display: block;
    margin-bottom: -60px; 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
  top: -10px; 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
    top: -10px;
    left: 0px;
    width: 50%;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    padding-left: 30px; 
  } 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
    bottom: auto;
    top: 100%;
    margin-top: 75px; 
  } 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event .event__content {
    margin-top: 150px;
    bottom: auto;
    height: 320px; 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event .timeline-event-image {
  position: absolute;
  right: calc(100% + 30px);
  left: auto;
  top: 0; 
}

@media screen and (min-width: 992px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event .timeline-event-image {
    padding-bottom: 10px;
    top: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event__content {
  padding-bottom: 150px; 
}

@media screen and (max-width: 991px) {
  #about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event__content {
    padding-bottom: 0; 
  } 
}

#about-timeline.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .timeline-event-image {
  position: absolute;
  bottom: 0;
  left: 63px; 
}

#about-timeline.roadmap .roadmap__events__event:before, #about-timeline .roadmap .roadmap__events__event:after {
  background-color: #606060; 
}

#about-timeline.roadmap .roadmap__events__event:before {
  z-index: 1;
  background: #FFF;
  border: 1px solid #606060; 
}

#about-timeline.roadmap .roadmap__events:after {
  background-color: #606060; 
}

#about-timeline.roadmap .roadmap__events__event:after {
  background-color: #606060; 
}