.page-careers-single {
  padding-top: 97px;
  padding-bottom: 92px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .page-careers-single {
      padding-bottom: 200px; } }
  .page-careers-single header.title {
    margin-bottom: 2em;
    border-bottom: 2px solid #E0E0E0; }
    @media screen and (max-width: 767px) {
      .page-careers-single header.title {
        margin-bottom: 1.3em; } }
    .page-careers-single header.title h1 {
      font-size: 24px;
      color: #00040A;
      margin-top: 0.75em; }
      @media screen and (max-width: 767px) {
        .page-careers-single header.title h1 {
          font-size: 18px; } }
    .page-careers-single header.title .location {
      font-size: 18px;
      margin-bottom: 1.5em; }
      @media screen and (max-width: 767px) {
        .page-careers-single header.title .location {
          font-size: 12px; } }
      @media screen and (max-width: 991px) {
        .page-careers-single header.title .location {
          font-size: 14px; } }
  @media screen and (min-width: 992px) {
    .page-careers-single .section-image--pull-right {
      margin-top: 50px; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .page-careers-single header.title {
    border-bottom: 0; }
  .page-careers-single header.title .header-content {
    border-bottom: 2px solid #b0b0b0; } }

@media screen and (min-width: 768px) {
  .page-careers-single header.title h1 {
    margin-top: 1.5em; } }

.page-careers-single .main ul {
  max-width: 40em;
  padding-left: 18px; }

.page -careers-single .main p, .page-careers-single .main ul {
  margin-bottom: 1.5em; }

.page-careers-single .main a {
  color: #00040A; }

.page-careers-single .main .share-icon {
  display: inline-block;
  background: url("../images/careers.icon.share.png") 50% 50%/cover no-repeat;
  width: 30px;
  height: 20px;
  text-indent: -99999em;
  margin-left: 6px;
  overflow: hidden; }

.page-careers-single .main .back {
  margin-top: 3em; }

@media screen and (max-width: 991px) {
  .page-careers-single .main * {
    font-size: 14px; } }

@media screen and (max-width: 767px) {
  .page-careers-single .main * {
    font-size: 12px; } }