@import url('//hello.myfonts.net/count/3ae54f');

@font-face {
	font-family: 'PlantinMTPro-BoldCondensed';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_0_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_0_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_0_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_0_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_0_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-BoldItalic';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_1_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_1_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_1_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_1_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_1_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-Bold';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_2_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_2_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_2_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_2_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_2_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-SemiboldIt';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_3_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_3_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_3_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_3_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_3_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-LightItalic';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_4_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_4_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_4_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_4_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_4_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-Semibold';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_5_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_5_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_5_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_5_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_5_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-Regular';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_6_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_6_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_6_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_6_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_6_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-Light';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_7_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_7_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_7_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_7_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_7_0.ttf') format('truetype');
}


@font-face {
	font-family: 'PlantinMTPro-Italic';
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_8_0.eot');
	src: url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_8_0.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_8_0.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_8_0.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3AE54F_8_0.ttf') format('truetype');
}

// ELEPHANT 
@font-face{
	font-family:'Elephant-Medium-Italic';
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/1ec7adc0-c18f-4fe6-bdac-1e88b2aa4574.eot?#iefix');
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/1ec7adc0-c18f-4fe6-bdac-1e88b2aa4574.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/0110f485-9334-4d99-a5cd-ea83781db786.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/818378fd-fbbf-4260-a603-27a280f9b476.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/499c321b-40b0-42e4-80ab-3249e3b64392.ttf') format('truetype');
}
@font-face{
	font-family:'Elephant-Light-Italic';
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/61b2ff6b-181f-4f94-871c-6ae76e8ae2b5.eot?#iefix');
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/61b2ff6b-181f-4f94-871c-6ae76e8ae2b5.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/43a07ac0-c77b-4de7-9c03-f05a083a557e.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/8be9a62e-d145-45db-b314-16ceff8a16d4.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/0f5ba812-c9f6-48cb-8ea8-7cb35cd7bc9d.ttf') format('truetype');
}
@font-face{
	font-family:'Elephant-Black';
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/b3e1fa04-20a7-4f34-9b62-f00236a116d9.eot?#iefix');
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/b3e1fa04-20a7-4f34-9b62-f00236a116d9.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/d6932d39-1142-4da0-833c-9f9a93b22a54.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/6e85bcac-995f-417b-8f65-14674e3fb0e7.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/9f515e23-33ac-4782-9846-e1c90ec5f2ee.ttf') format('truetype');
}
@font-face{
	font-family:'Elephant-Black-Obli';
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3d424b1f-699b-420b-a690-fe2486ef18ff.eot?#iefix');
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/3d424b1f-699b-420b-a690-fe2486ef18ff.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/26b9f309-54ec-45cb-8291-026623cb673c.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/b6680467-2492-48e7-9f3e-b4f2aecafcd8.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/a9571c92-6241-43ce-86d4-6c1606a39458.ttf') format('truetype');
}
@font-face{
	font-family:'Elephant-Light';
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/c7079dc6-7b95-4250-98fb-18ddba466228.eot?#iefix');
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/c7079dc6-7b95-4250-98fb-18ddba466228.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/4bc72f41-45b3-46b0-934d-680d0647dd62.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/b254ec31-b3c5-4bf5-9e7a-d0e8930c055c.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/75768200-4646-416d-9bc2-981c663fa4cf.ttf') format('truetype');
}
@font-face{
	font-family:'Elephant-Medium';
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/7ba3c90b-e056-466d-8bf0-7964ac44cba4.eot?#iefix');
	src:url('/wp-content/themes/boltthreads/dist/fonts/webfonts/7ba3c90b-e056-466d-8bf0-7964ac44cba4.eot?#iefix') format('embedded-opentype'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/113a29b2-e2e3-4db5-a894-5140937d2c33.woff2') format('woff2'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/a4d3cbd6-4b1f-4945-ad64-ce0b419cb0ca.woff') format('woff'),
	url('/wp-content/themes/boltthreads/dist/fonts/webfonts/f798e288-d105-4fda-ae34-458b3792394a.ttf') format('truetype');
}