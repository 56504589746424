/**
 * Main Colors
 */
$primary-color: #3f81e7;

/**
 * Distances
 */
$gap: 25px;

/**
 * Mobile Breakpoints
 */
$screen-xs: 480px;
$screen-sm: 600px;
$screen-md: 992px;

/**
 * Misc
 */
$transition-function: cubic-bezier(0.25, 0.1, 0.25, 1.30);