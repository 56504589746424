$obsidian : #00040A;
$emerald : #126A6B;
$mahogany : #592237;

@mixin preheader {
	color: $obsidian;
	font-family: 'PlantinMTPro-LightItalic';
	font-weight: normal;
	font-style: normal;
	font-size: calc(36px / 2);
	
	@media screen and (min-width: 992px) {
		font-size: calc(60px / 2);
	}

	&:after {
		content: "\2014";
		display: inline-block;
	}
}

@mixin header1 {
	font-family: 'Elephant-Black';
	font-weight: normal;
	font-style: normal;
	color: $emerald;
	font-size: calc(48px / 2);
	
	@media screen and (min-width: 992px) {
		font-size: calc(80px / 2);
	}	
}

@mixin sectionheader {
	font-family: 'Elephant-Medium';
	text-transform: uppercase;
	font-weight: normal;
	font-style: normal;
	color: $obsidian;
	font-size: calc(48px / 2);
	
	@media screen and (min-width: 992px) {
		font-size: calc(80px / 2);
	}	
}

@mixin header2 {
	font-family: 'Elephant-Black';
	font-weight: normal;
	font-style: normal;
	color: $emerald;
	font-size: calc(24px / 2);
	
	@media screen and (min-width: 992px) {
		font-size: calc(44px / 2);
	}		
}

@mixin header3 {
	font-family: 'Elephant-Medium';
	font-weight: normal;
	font-style: normal;
	color: $emerald;
	font-size: calc(36px / 2);
	
	@media screen and (min-width: 992px) {
		font-size: calc(60px / 2);
	}	
}

@mixin bodystyle {
	font-family: 'PlantinMTPro-Regular';
	font-weight: normal;
	font-style: normal;

	// font-size: calc(24px / 2);
	font-size: 16px;
	
	@media screen and (min-width: 992px) {
		// font-size: calc(48px / 2);
		font-size: 18px;
	}

}

@mixin antiindent {
	text-indent: -1em;
    padding-left: 1em;
}

@mixin buttonstyle {
	display: inline-block;
	font-family: 'Elephant-Medium';
	background: $emerald;
	color: #FFF;
	padding: 7px 25px;  
	font-size: calc(24px / 2);
	text-transform: uppercase;
	
	@media screen and (min-width: 992px) {
		font-size: calc(44px / 2);
	}
}