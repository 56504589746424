.page-technology {
  position: relative;
  overflow-x: hidden;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out; }
  .page-technology .scroll-cta {
    cursor: pointer; }
  .page-technology .lockup {
    position: relative;
    overflow: hidden;
    background: url("../images/technology.bg.lockup.png") 50% 50%/cover no-repeat;
    color: #fff; }
  @media screen and (max-width: 767px) {
    .page-technology .lockup {
      margin-top: -14px; } }
  .page-technology .lockup .container-fluid {
    z-index: 9;
    position: relative;
    position: relative;
    top: calc(50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .page-technology .lockup p {
    margin-bottom: 1em;
    font-size: 18px; }
  @media screen and (min-width: 768px) {
    .page-technology .lockup p {
      font-size: 24px; } }
  @media screen and (min-width: 1200px) {
    .page-technology .lockup p {
      font-size: 36px;
      line-height: 49px; } }
  .page-technology .scroll-cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 1;
    background: white url("../images/home.lockup.scrollcta.png") 50% 50% no-repeat; }
  .page-technology .steps .row {
    margin-bottom: 60px; }
  .page-technology .steps figure {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 12px; }
  @media screen and (min-width: 768px) {
    .page-technology .steps figure {
      font-size: 16px;
      padding-top: 69px;
      padding-bottom: 69px; } }
  @media screen and (min-width: 1200px) {
    .page-technology .steps figure {
      font-size: 24px; } }
  .page-technology .steps figure figcaption {
    margin: 0 auto;
    text-align: center; }
  @media screen and (min-width: 768px) {
    .page-technology .steps figure figcaption {
      width: 30em; } }
  .page-technology .steps figure img {
    display: block;
    margin: 0 auto 35px;
    opacity: 0;
    max-width: 60px; }
  .page-technology .steps figure #tech-icon-0 {
    max-width: 48px; }
  .page-technology .steps figure #tech-icon-1 {
    max-width: 69px; }
  .page-technology .process-lockup {
    position: relative;
    overflow: hidden;
    background: url("../images/technology.bg.process.jpg") 50% 50%/cover no-repeat;
    color: #fff; }
  .page-technology .process-lockup .container-fluid {
    z-index: 9;
    position: relative;
    position: relative;
    top: calc(50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .page-technology .process-lockup p {
    margin-bottom: 1em;
    font-size: 18px; }
  @media screen and (min-width: 768px) {
    .page-technology .process-lockup p {
      font-size: 24px; } }
  @media screen and (min-width: 1200px) {
    .page-technology .process-lockup p {
      font-size: 36px;
      line-height: 49px; } }
  .page-technology .faq {
    overflow: hidden; }
  @media screen and (max-width: 767px) {
    .page-technology .faq {
      margin-bottom: 30px; } }
  .page-technology .faq .title h1 {
    font-size: 18px;
    text-align: center;
    color: #00040A;
    margin-bottom: 0;
    padding-bottom: 2em; }
  @media screen and (max-width: 767px) {
    .page-technology .faq .title h1 {
      margin-top: 30px; } }
  @media screen and (min-width: 768px) {
    .page-technology .faq .title h1 {
      margin-top: 3em;
      font-size: 24px; } }
  @media screen and (min-width: 1200px) {
    .page-technology .faq .title h1 {
      font-size: 36px; } }
  .page-technology .faq .category .category-name {
    text-align: center;
    font-size: 12px;
    margin-top: 1em;
    margin-bottom: 1em; }
  @media screen and (min-width: 768px) {
    .page-technology .faq .category .category-name {
      margin-bottom: 25px;
      margin-top: 25px;
      font-size: 14px; } }
  @media screen and (min-width: 1200px) {
    .page-technology .faq .category .category-name {
      font-size: 24px; } }
  .page-technology .faq .category .answer {
    display: none;
    font-size: 12px; }
  @media screen and (min-width: 768px) {
    .page-technology .faq .category .answer {
      width: 40em;
      margin: 0 auto; } }
  @media screen and (min-width: 1200px) {
    .page-technology .faq .category .answer {
      font-size: 18px; } }
  .page-technology .faq .category .answer dd {
    margin-bottom: 2em; }
  .page-technology .faq .category .expand {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto 25px;
    background: url("../images/technology.icon.expand.png") 50% 50%/cover no-repeat;
    text-indent: -99999em;
    overflow: hidden; }
  @media screen and (max-width: 767px) {
    .page-technology .faq .category .expand {
      margin: 1em auto; } }
  .page-technology .faq .category.active .category-name {
    color: #00040A;
    font-weight: 700; }
  .page-technology .faq .category.active .expand {
    background-image: url("../images/technology.icon.contract.png"); }