.page-beebe .sections-container .caption, .page-beebe .sections-container .product-description {
  margin-bottom: 0;
  padding: 10px;
  padding-bottom: 0;
  margin-left: -10px; 
}

// .page-beebe .products-section .parallax-section .row {
//   display: flex;
// }

// .page-beebe .products-section .parallax-section .section--left-image .col-md-6 {
//   order: 2;
//   background-color: red;
// }



.page-beebe h4 {
  margin-top: 1em;
  font-family: 'PlantinMTPro-LightItalic'; 
}

@media screen and (min-width: 992px) {
  .page-beebe .centered-header-section {
    margin-bottom: 75px; 
  } 
}

.page-beebe .parallax-section-1 strong {
  // font-family: 'Elephant-Black';
  font-style: normal;
  font-weight: bold;
}

.page-beebe .parallax-section-1 a.button-link, .page-beebe .parallax-product-2-section a.button-link {
  margin-top: .8em;
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #126A6B;
  color: #FFF;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
  white-space: nowrap;
  &:hover, &:focus {
    text-decoration: none;
  }
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-1 a.button-link, .page-beebe .parallax-product-2-section a.button-link {
    font-size: calc(44px / 2); 
    &:hover, &:focus {
      text-decoration: none;
    }
  } 
}

@media screen and (max-width: 991px) {
  .page-beebe .parallax-section-1 .caption {
    margin-bottom: 25px; 
  } 
}

.page-beebe .parallax-section-1 .section-image--pull-right {
  margin-top: 50px; 
}
@media screen and (max-width: 991px) {
  .page-beebe .parallax-section-1 .section-image--pull-right {
    display: none !important; 
  } 
}

@media screen and (max-width: 991px) {
  .page-beebe .parallax-section-1 .section-image-smaller {
    width: 344px;
    height: 318px;
    background-size: cover;
    background-position: 50% 50%;
    margin-left: auto;
    position: relative;
    left: 48px;
    overflow: hidden; 
  } 
}

@media screen and (max-width: 991px) {
  .page-beebe .parallax-section-timeline .timeline-section {
    margin-bottom: 0; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe section.section--left-image.parallax-section-deep-science .product-img {
    left: 47px; 
  } 
}

@media screen and (max-width: 991px) {
  .page-beebe .parallax-section-deep-science {
    margin-top: 0; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-deep-science {
    margin-bottom: 75px; 
  }
  .page-beebe .parallax-section-deep-science .section-image-container {
    position: relative; 
  }
  .page-beebe .parallax-section-deep-science .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  }
  .page-beebe .parallax-section-deep-science.section--left-image {
    min-height: 890px;
    height: 60vh; 
  }
  .page-beebe .parallax-section-deep-science .sm-full {
    min-height: 890px;
    height: 60vh; 
  } 
}

.page-beebe .supply-section .product-description {
  padding: 10px;
  margin-left: -10px; 
}
.page-beebe .supply-section .product-description .section-text-larger p {
  max-width: 92%; 
}

@media screen and (min-width: 992px) {
  .page-beebe .supply-section .section-image-container {
    position: relative; 
  }
  .page-beebe .supply-section .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  }
  .page-beebe .supply-section.section--right-image {
    min-height: 800px;
    height: 65vh; 
  }
  .page-beebe .supply-section .sm-full {
    min-height: 800px;
    height: 65vh; 
  } 
}

// -----------------
// Signup Section 
// -----------------

.page-beebe #section-signup {
  padding: 50px; 
  background: #000;
  position: relative;
}

.page-beebe #section-signup:before {
  content: "";
  display: block;
  height: 100%;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  right: -100vw;
  bottom: 0;
}

.page-beebe #section-signup .row {
  flex-flow: wrap;
}
@media screen and (min-width: 992px) {
  .page-beebe #section-signup .row {
    display: flex;
    align-items: center;
  }
}


.page-beebe #section-signup a.button-link {
  display: inline-block;
  font-family: 'Elephant-Medium';
  background: #FFF;
  color: #000;
  padding: 7px 25px;
  font-size: calc(24px / 2);
  text-transform: uppercase; 
  &:hover, &:focus {
    text-decoration: none;
  }
}
@media screen and (min-width: 992px) {
 .page-beebe #section-signup a.button-link {
  font-size: calc(44px / 2); 
  position: relative;
  &:hover, &:focus {
    text-decoration: none;
  }
} 
}

.page-beebe #section-signup *, 
.page-beebe #section-signup h1, 
.page-beebe #section-signup h2, 
.page-beebe #section-signup h3, 
.page-beebe #section-signup p {
  color: #FFF;
  position: relative; 
}

// .page-beebe #section-signup .col-xs-12 {
//   position: relative;
//   padding: 80px 0px 80px 100px; 
// }

.page-beebe #section-signup h3 {
  max-width: 550px;
  // font-size: 1.62em; 
}
.page-beebe #section-signup h3:after {
  display: none; 
}





.page-beebe h4 {
  color: #000;
  font-size: 1.62em; 
}
.page-beebe h4:after {
  display: none; 
}


// product section 

@media screen and (min-width: 992px) {
  .page-beebe .parallax-product-2-section {
    min-height: 830px;
  }
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-product-2-section .sm-full {
    min-height: 730px; 
  } 
}

.page-beebe .parallax-product-2-section ol {
  margin-left: 0;
  padding-left: 1.5em;
  font-style: italic;
  line-height: 1.75em;
  font-size: 0.9em;
}

.page-beebe .parallax-product-2-section ol li {
  margin-bottom: .5em;
}


// ---- parallax-product-1-section ----
@media screen and (min-width: 992px) {
  .page-beebe .parallax-product-1-section {
    min-height: 780px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-product-1-section .sm-full {
    min-height: 980px; 
  } 
}

.page-beebe .parallax-product-1-section h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-beebe .parallax-product-1-section h1 {
    max-width: 440px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-product-1-section .section-image-container {
    position: relative; 
  }
  .page-beebe .parallax-product-1-section .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  } 
}

// ---- parallax-section-2 ----
@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-2 {
    min-height: 750px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-2 .sm-full {
    min-height: 750px; 
  } 
}

.page-beebe .parallax-section-2 h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-2 h1 {
    max-width: 440px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-2 .section-image-container {
    position: relative; 
  }
  .page-beebe .parallax-section-2 .product-img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%); 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-3 {
    height: 60vh;
    min-height: 570px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-3 .sm-full {
    height: 60vh;
    min-height: 570px; 
  } 
}

.page-beebe .parallax-section-3 h1 {
  max-width: 330px; 
}
@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-3 h1 {
    max-width: 440px; 
  } 
}

@media screen and (min-width: 992px) {
  .page-beebe .parallax-section-3 .section-image-container {
    position: relative;
    top: -5px; 
  } 
}






















